import React from "react";
import { Container, Row, Col, Card, Spinner } from "react-bootstrap";
import "./PricingView.css";
import TerminologySidebar from "../component/TerminologySidebar"; // Adjust the path if necessary
import { CheckoutButton } from "../component/CheckoutButton";
import { CallToActionButton } from "../component/CallToActionButton";
import { Helmet } from "react-helmet";

export const PricingView = ({
  products,
  header,
  subtitle,
  currentproduct,
  callToAction,
  isLoading,
  includeTerminology,
  showCallToActionButton,
  showStripeCheckoutButton,
}) => {
  return (
    <>
      <Helmet>
        <title>Pricing</title>
        <meta name="description" content="SessionScreen Pricing" />
      </Helmet>
      <Container className="my-5">
        <div className="text-center mb-5">
          <h1>{header}</h1>
          <h4>{subtitle}</h4>
        </div>
        {includeTerminology && (
          <div className="text-center mb-4">
            <TerminologySidebar />
          </div>
        )}
        {isLoading ? (
          <Spinner />
        ) : (
          <Row>
            {products.map((product, index) => (
              <Col md={4} className="d-flex" key={index}>
                <Card
                  className={`flex-fill mb-4 ${currentproduct && currentproduct.name === product.name ? "shadow-sm disabled-card" : ""}`}
                >
                  <Card.Header className="text-center">
                    {product.name}
                  </Card.Header>
                  <Card.Body>
                    <Card.Title className="text-center">
                      {parseFloat(product.original_price).toFixed(2) >
                        parseFloat(product.price).toFixed(2) && (
                        <span
                          style={{
                            textDecoration: "line-through",
                            marginRight: "10px",
                          }}
                        >
                          ${parseFloat(product.original_price).toFixed(2)}
                        </span>
                      )}
                      ${parseFloat(product.price).toFixed(2)}{" "}
                      <span style={{ fontSize: "smaller", color: "#6c757d" }}>
                        + tax
                      </span>
                    </Card.Title>
                    <div className="card-text">
                      <ul>
                        {product.features.map((feature, featureIndex) => (
                          <li key={featureIndex}>{feature}</li>
                        ))}
                      </ul>
                    </div>
                    {showCallToActionButton && (
                      <CallToActionButton callToAction={callToAction} />
                    )}
                    {showStripeCheckoutButton && (
                      <CheckoutButton
                        productId={product.id}
                        callToAction={callToAction}
                      />
                    )}
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        )}
      </Container>
    </>
  );
};
