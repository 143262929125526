import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../axiosConfig";
import htmr from "htmr";
import { useQuestionStart } from "../hooks/useQuestionStart";
import { useRdpWebSocketConnection } from "../hooks/useRdpWebSocketConnection";
import { SubmitCreateQuestionView } from "../views/SubmitCreateQuestionView";

export const SubmitRdpQuestionContainer = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  let { questionId } = useParams();
  const [questionTitle, setQuestionTitle] = useState("");
  const [questionDescription, setQuestionDescription] = useState("");
  const [sessionId, setSessionId] = useState(null);
  const [uuid, setUuid] = useState(null);
  const [isCursorInCanvas, setIsCursorInCanvas] = useState(false);
  const videoRef = useRef(null);

  const websocketPath = "question";

  useEffect(() => {
    const fetchQuestion = async () => {
      try {
        const url = `${apiBaseUrl}/interviewers/api/v1/questions/${questionId}`;
        const response = await axiosInstance.get(url);
        const responseData = response.data;
        setQuestionDescription(htmr(responseData.description));
        setQuestionTitle(htmr(responseData.title));
        setSessionId(responseData.id);
        setUuid(responseData.uuid);
      } catch (error) {
        console.error("Error fetching question:", error);
      }
    };

    fetchQuestion();
  }, [apiBaseUrl, questionId]);

  const isQuestionReady = useQuestionStart(apiBaseUrl, sessionId);

  useRdpWebSocketConnection(
    isQuestionReady && sessionId,
    apiBaseUrl,
    sessionId,
    videoRef,
    websocketPath,
    uuid,
  );

  const onMouseOver = () => {
    setIsCursorInCanvas(true);
  };

  const onMouseLeave = () => {
    setIsCursorInCanvas(false);
  };

  return (
    <SubmitCreateQuestionView
      isQuestionReady={isQuestionReady}
      questionTitle={questionTitle}
      questionDescription={questionDescription}
      terminalRef={null}
      questionId={questionId}
      type="rdp"
      videoRef={videoRef}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      cursorInTerminal={isCursorInCanvas}
    />
  );
};
