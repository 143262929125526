import React from "react";
import { useCommonContainerLogic } from "./useCommonContainerLogic";
import { PricingContainer } from "./PricingContainer";
import { LoadingNotice } from "../component/LoadingNotice";
import { EmailVerificationNotice } from "../component/EmailVerificationNotice";

export const PurchaseContainer = () => {
  const { isLoading, authLoading, emailVerified } = useCommonContainerLogic();
  const header = "Purchase only what you need to hire the best candidates.";
  const subtitle =
    "Absolutely no subscription required. No fake expiration dates.";
  const callToAction = "Purchase";

  if (isLoading || authLoading) {
    return <LoadingNotice />;
  }

  if (!emailVerified) {
    return <EmailVerificationNotice />;
  }

  return (
    <>
      <PricingContainer
        header={header}
        subtitle={subtitle}
        callToAction={callToAction}
        includeBadHire={false}
        includeTerminology={false}
        showCallToActionButton={false}
        showStripeCheckoutButton={true}
      />
    </>
  );
};
