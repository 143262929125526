import { useEffect, useRef } from "react";
import JSMpeg from "@cycjimmy/jsmpeg-player";

export const useRdpWebSocketConnection = (
  isReady,
  apiBaseUrl,
  questionId,
  videoRef,
  websocketPath,
  uuid,
) => {
  const wsRef = useRef(null);
  const playerRef = useRef(null);
  const MAX_RETRIES = 5;
  const RETRY_BACKOFF_FACTOR = 2000; // in milliseconds

  useEffect(() => {
    const currentVideoRef = videoRef.current;
    if (!currentVideoRef || !isReady) {
      return;
    }

    const protocol = window.location.protocol === "https:" ? "wss://" : "ws://";
    const fixedApiBaseHostname = apiBaseUrl.replace(/^https?:\/\//, "");
    const wsUrl = `${protocol}${fixedApiBaseHostname}/ws/execute_command/${websocketPath}/rdp/${uuid}/`;

    let retries = 0;
    let cancelled = false;

    const handleMouseEvent = (event) => {
      const rect = currentVideoRef.getBoundingClientRect();
      const scaleX = currentVideoRef.width / rect.width;
      const scaleY = currentVideoRef.height / rect.height;
      const x = (event.clientX - rect.left) * scaleX;
      const y = (event.clientY - rect.top) * scaleY;
      const message = JSON.stringify({
        type: "mouse",
        event: event.type,
        x,
        y,
        button: event.button,
      });
      if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
        wsRef.current.send(message);
      }
    };

    const handleContextMenuEvent = (event) => {
      event.preventDefault();
    };

    const handleKeyboardEvent = (event) => {
      event.preventDefault();
      const message = JSON.stringify({
        type: "keyboard",
        event: event.type,
        key: event.key,
        code: event.code,
      });
      if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
        wsRef.current.send(message);
      }
    };

    const connectWebSocket = () => {
      const ws = new WebSocket(wsUrl);
      wsRef.current = ws;

      ws.onopen = () => {
        retries = 0; // reset retries on successful connection
        playerRef.current = new JSMpeg.Player(wsUrl, {
          canvas: currentVideoRef,
          video: true,
          audio: false,
        });
      };

      ws.onerror = (error) => {
        console.error("WebSocket error:", error);
      };

      ws.onclose = () => {
        if (!cancelled && retries < MAX_RETRIES) {
          const waitTime = RETRY_BACKOFF_FACTOR * Math.pow(2, retries);
          setTimeout(() => {
            retries += 1;
            connectWebSocket();
          }, waitTime);
        } else if (cancelled) {
          console.log("Retries canceled due to component unmount");
        } else {
          console.error("Max retries reached. Could not connect to WebSocket.");
        }
      };

      currentVideoRef.addEventListener("mousedown", handleMouseEvent);
      currentVideoRef.addEventListener("mousemove", handleMouseEvent);
      currentVideoRef.addEventListener("mouseup", handleMouseEvent);
      currentVideoRef.addEventListener("contextmenu", handleContextMenuEvent);
      document.addEventListener("keydown", handleKeyboardEvent);
      document.addEventListener("keyup", handleKeyboardEvent);
    };

    connectWebSocket();

    return () => {
      cancelled = true;
      if (wsRef.current) {
        wsRef.current.close();
      }

      if (playerRef.current) {
        playerRef.current.destroy();
      }

      if (currentVideoRef) {
        currentVideoRef.removeEventListener("mousedown", handleMouseEvent);
        currentVideoRef.removeEventListener("mousemove", handleMouseEvent);
        currentVideoRef.removeEventListener("mouseup", handleMouseEvent);
        currentVideoRef.removeEventListener(
          "contextmenu",
          handleContextMenuEvent,
        );
      }

      document.removeEventListener("keydown", handleKeyboardEvent);
      document.removeEventListener("keyup", handleKeyboardEvent);
      document.removeEventListener("contextmenu", handleContextMenuEvent);
    };
  }, [uuid, apiBaseUrl, questionId, isReady, videoRef, websocketPath]);
};
