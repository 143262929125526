import React from "react";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";

export const Sidebar = () => {
  return (
    <div className="sidebar">
      <Nav variant="pills" className="flex-column">
        <Nav.Item>
          <NavLink
            to="/dashboard/openings"
            className={({ isActive }) =>
              isActive ? "nav-link active" : "nav-link"
            }
          >
            Openings
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink
            to="/dashboard/sessions"
            className={({ isActive }) =>
              isActive ? "nav-link active" : "nav-link"
            }
          >
            Interviews
          </NavLink>
        </Nav.Item>
        <Nav.Item>
          <NavLink
            to="/dashboard/questions"
            className={({ isActive }) =>
              isActive ? "nav-link active" : "nav-link"
            }
          >
            Questions
          </NavLink>
        </Nav.Item>
      </Nav>
      <br />
    </div>
  );
};
