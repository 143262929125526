import React, { useRef, useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import "xterm/css/xterm.css";
import { useSessionStart } from "../hooks/useSessionStart";
import { useWebSocketConnection } from "../hooks/useWebSocketConnection";
import { SessionDetailView } from "../views/SessionDetailView";
import axiosInstance from "../axiosConfig";

export const SessionDetailContainer = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const { sessionId } = useParams();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const tokenParam = params.get("token");
  let token = "";
  if (tokenParam !== null && tokenParam !== undefined && tokenParam !== "") {
    token = tokenParam;
  }
  const terminalRef = useRef(null);
  // eslint-disable-next-line
  const [showAlert, setShowAlert] = useState(true);
  const usp = useSessionStart(apiBaseUrl, sessionId, token);
  const isSessionReady = usp.isReady;
  const isClosed = usp.isClosed;
  const isStarted = usp.isStarted;
  const [isClosingSession, setIsClosingSession] = useState(false);
  const [questionTitle, setQuestionTitle] = useState("");
  const [questionDescription, setQuestionDescription] = useState("");
  const [uuid, setUuid] = useState(null);

  const websocketPath = "q_attempt";

  useEffect(() => {
    const fetchSession = async () => {
      try {
        let url = `${apiBaseUrl}/interviewers/api/v1/question-attempts/basic/${sessionId}`;
        if (token !== null && token !== "") {
          url += `?token=${token}`;
        }
        const response = await axiosInstance.get(url);
        const responseData = response.data;
        setQuestionDescription(responseData.description);
        setQuestionTitle(responseData.title);
        setUuid(responseData.uuid);
      } catch (error) {
        console.error("Error fetching question attempt:", error);
      }
    };

    fetchSession();
  }, [apiBaseUrl, sessionId, token]);

  useWebSocketConnection(
    isSessionReady && uuid,
    apiBaseUrl,
    sessionId,
    terminalRef,
    websocketPath,
    uuid,
  );

  const handleAlertClose = () => setShowAlert(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsClosingSession(true);
      const sessionUrl = `${apiBaseUrl}/interviewers/api/v1/question-attempts/close/${sessionId}`;
      await axiosInstance.put(sessionUrl);
    } catch (error) {
      setIsClosingSession(false);
      console.error(
        "Error saving question:",
        error.response ? error.response.data : error,
      );
    }
  };

  return (
    <SessionDetailView
      isSessionReady={isSessionReady}
      questionTitle={questionTitle}
      questionDescription={questionDescription}
      handleAlertClose={handleAlertClose}
      terminalRef={terminalRef}
      sessionId={sessionId}
      handleSubmit={handleSubmit}
      isClosingSession={isClosingSession || isClosed}
      isStarted={isStarted}
      type="ssh"
    />
  );
};
