import React, { useState } from "react";
import axios from "axios";
import { ContactUsView } from "../views/ContactUsView";

export const ContactUsContainer = () => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [formData, setFormData] = useState({
    subject: "",
    email: "",
    message: "",
  });
  const [showPhone, setShowPhone] = useState(false);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setError("");
    setSuccess("");
  };

  const toggleShowPhone = () => setShowPhone(!showPhone);

  const canSubmit = () => {
    if (!formData.subject || !formData.email || !formData.message) {
      setError("Please fill in all fields.");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!canSubmit()) return;

    try {
      const contactUsUrl = `${apiBaseUrl}/api/v1/contact-us`;
      await axios.post(contactUsUrl, formData);
      // Clear the form
      setFormData({
        subject: "",
        email: "",
        message: "",
      });
      setSuccess("Your message has been sent successfully!");
    } catch (error) {
      let errorMessage = "Error sending message. Please try again later.";
      setError(errorMessage);
    }
  };

  return (
    <ContactUsView
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      toggleShowPhone={toggleShowPhone}
      showPhone={showPhone}
      error={error}
      success={success}
      formData={formData}
    />
  );
};
