import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Sidebar } from "../component/Sidebar";
import { SearchBar } from "../component/SearchBar";
import { DataTable } from "../component/DataTable";
import { LoadingNotice } from "../component/LoadingNotice";
import { EmailVerificationNotice } from "../component/EmailVerificationNotice";
import { InactiveQuestionsTable } from "../component/InactiveQuestionsTable";
import { Helmet } from "react-helmet";

import "./DataView.css";
import { JobOpeningStatus } from "../component/JobOpeningStatus";

export const DataView = ({
  orgName,
  data,
  columns,
  searchTerm,
  setSearchTerm,
  dataType,
  CreateButtonComponent,
  isLoading,
  authLoading,
  emailVerified,
  onContextMenu,
  contextMenu,
  contextMenuRef,
  contextMenuItems,
  searchAction,
  lengthOfInitialData,
}) => {
  if (isLoading || authLoading) {
    return <LoadingNotice />;
  }

  if (!emailVerified) {
    return <EmailVerificationNotice />;
  }

  const isEmpty = data.length === 0;

  return (
    <>
      <Helmet>
        <title>SessionScreen</title>
        <meta name="description" content="SessionScreen" />
      </Helmet>
      <Container fluid className="mt-3">
        <Row>
          <Col
            xs={12}
            md={3}
            lg={2}
            id="sidebar-wrapper"
            className="border-right"
          >
            <Sidebar />
          </Col>
          <Col xs={12} md={9} lg={10} id="page-content-wrapper">
            <Container>
              <Row className="align-items-center mb-4">
                <Col>
                  <h2>
                    {orgName} {dataType}
                  </h2>
                  <JobOpeningStatus />
                </Col>
                <Col xs="auto">
                  {CreateButtonComponent && <CreateButtonComponent />}
                </Col>
              </Row>

              <Row className="mb-4">
                <Col md={8}>
                  <SearchBar
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    placeholder={`Search ${dataType.toLowerCase()} by title`}
                    searchAction={searchAction}
                  />
                </Col>
                <Col md={4} className="text-md-right">
                  <InactiveQuestionsTable />
                </Col>
              </Row>

              <Row>
                <Col>
                  {isEmpty ? (
                    <Card className="text-center my-5 py-5">
                      <Card.Body>
                        <Card.Title>No {dataType} Found</Card.Title>

                        {lengthOfInitialData === 0 ? (
                          <Card.Text>
                            It looks like you haven't created any{" "}
                            {dataType.toLowerCase()} yet.
                          </Card.Text>
                        ) : (
                          <Card.Text>
                            Nothing found matching the search term.
                          </Card.Text>
                        )}
                        <Row className="justify-content-center">
                          <Col xs="auto">
                            <CreateButtonComponent />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  ) : (
                    <div className="table-responsive">
                      <DataTable
                        data={data}
                        columns={columns}
                        onContextMenu={onContextMenu}
                        contextMenu={contextMenu}
                        contextMenuRef={contextMenuRef}
                        contextMenuItems={contextMenuItems}
                      />
                    </div>
                  )}
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
};
