import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export const CallToActionButton = ({ callToAction }) => {
  const navigate = useNavigate();
  const handleClick = async () => {
    navigate("/signup");
  };

  return <Button onClick={handleClick}>{callToAction}</Button>;
};
