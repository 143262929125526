import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CreateJobPostingView } from "../views/CreateJobPostingView";
import axiosInstance from "../axiosConfig";
import { useCommonContainerLogic } from "./useCommonContainerLogic";
import { marked } from "marked";

export const CreateJobPostingContainer = () => {
  const [jobPosting, setJobPosting] = useState({
    title: "",
    description: "",
    location: "",
    isActive: false,
    preassessment: "",
  });
  const [textFields, setTextFields] = useState([]);
  const [booleanFields, setBooleanFields] = useState([]);
  const [AIGenerating, setAIGenerating] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [address, setAddress] = useState(jobPosting.location);
  const [suggestions, setSuggestions] = useState([]);
  const [preassessmentQuestions, setPreassessmentQuestions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPreassessmentQuestions = async () => {
      try {
        const response = await axiosInstance.get(
          `${apiBaseUrl}/interviewers/api/v1/questions`,
        );
        const filteredQuestions = response.data.filter(
          (q) => q.contains_script,
        );
        setPreassessmentQuestions(filteredQuestions);
      } catch (error) {
        console.error("Error fetching pre-assessment questions:", error);
      }
    };

    fetchPreassessmentQuestions();
  }, [apiBaseUrl]);

  const handleAddressChange = async (value) => {
    setAddress(value);
    if (value.length > 2) {
      try {
        const response = await axiosInstance.get(
          `${apiBaseUrl}/api/v1/location-autocomplete`,
          {
            params: {
              query: value,
            },
          },
        );
        setSuggestions(response.data.predictions);
      } catch (error) {
        console.error("Error fetching location suggestions", error);
      }
    } else {
      setSuggestions([]);
    }
  };

  const handleSelect = (value) => {
    setAddress(value);
    handleChange({
      target: {
        name: "location",
        value: value,
      },
    });
    setSuggestions([]);
  };

  useCommonContainerLogic();

  const handleChange = (e) => {
    setJobPosting({ ...jobPosting, [e.target.name]: e.target.value });
  };

  const handleCheckboxChange = (e) => {
    setJobPosting({ ...jobPosting, [e.target.name]: e.target.checked });
  };

  const handleAddTextField = () => {
    setTextFields([...textFields, { name: "", required: false }]);
  };

  const handleAddBooleanField = () => {
    setBooleanFields([
      ...booleanFields,
      { name: "", correct_answer: false, required: false },
    ]);
  };

  const handleTextFieldChange = (index, value) => {
    const newFields = [...textFields];
    newFields[index].name = value;
    setTextFields(newFields);
  };

  const handleBooleanFieldChange = (index, value) => {
    const newFields = [...booleanFields];
    newFields[index].name = value;
    setBooleanFields(newFields);
  };

  const handleBooleanFieldCorrectAnswerChange = (index, value) => {
    const newFields = [...booleanFields];
    let answer = false;
    if (value !== "false") {
      answer = true;
    }
    newFields[index].correct_answer = answer;
    setBooleanFields(newFields);
  };

  const handleTextFieldRequiredChange = (index) => {
    const newFields = [...textFields];
    newFields[index].required = !newFields[index].required;
    setTextFields(newFields);
  };

  const handleBooleanFieldRequiredChange = (index) => {
    const newFields = [...booleanFields];
    newFields[index].required = !newFields[index].required;
    setBooleanFields(newFields);
  };

  const handleRemoveTextField = (index) => {
    setTextFields(textFields.filter((_, i) => i !== index));
  };

  const handleRemoveBooleanField = (index) => {
    setBooleanFields(booleanFields.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const jobPostingsUrl = `${apiBaseUrl}/jobs/api/v1/job/`;
      const data = {
        title: jobPosting.title,
        description: jobPosting.description,
        location: jobPosting.location,
        is_active: jobPosting.isActive,
        text_questions: textFields,
        boolean_questions: booleanFields,
        preassessment: jobPosting.preassessment,
      };
      await axiosInstance.post(jobPostingsUrl, data);
      navigate("/dashboard/openings");
    } catch (error) {
      console.error(
        "Error creating job posting:",
        error.response ? error.response.data : error,
      );
    }
  };

  const handleQuillChange = (name, value) => {
    handleChange({
      target: {
        name,
        value,
      },
    });
  };

  const handleAIGenerate = async (e) => {
    e.preventDefault();
    if (!jobPosting.title || !jobPosting.description || !jobPosting.location) {
      setAlertMsg("Please fill in all required fields.");
      return;
    }
    try {
      setAIGenerating(true);
      const AIGenerateUrl = `${apiBaseUrl}/jobs/api/v1/job/ai-complete`;
      const data = {
        title: jobPosting.title,
        description: jobPosting.description,
        location: jobPosting.location,
      };
      const response = await axiosInstance.post(AIGenerateUrl, data);
      const description = response.data.description;
      handleQuillChange("description", marked(description));
      setAIGenerating(false);
    } catch (error) {
      setAIGenerating(false);
      console.error(
        "Error creating job posting:",
        error.response ? error.response.data : error,
      );
    }
  };

  return (
    <CreateJobPostingView
      jobPosting={jobPosting}
      handleChange={handleChange}
      handleCheckboxChange={handleCheckboxChange}
      handleSubmit={handleSubmit}
      handleAIGenerate={handleAIGenerate}
      AIGenerating={AIGenerating}
      alertMsg={alertMsg}
      handleQuillChange={handleQuillChange}
      address={address}
      suggestions={suggestions}
      handleAddressChange={handleAddressChange}
      handleSelect={handleSelect}
      textFields={textFields}
      booleanFields={booleanFields}
      handleAddTextField={handleAddTextField}
      handleAddBooleanField={handleAddBooleanField}
      handleTextFieldChange={handleTextFieldChange}
      handleBooleanFieldChange={handleBooleanFieldChange}
      handleBooleanFieldCorrectAnswerChange={
        handleBooleanFieldCorrectAnswerChange
      }
      handleTextFieldRequiredChange={handleTextFieldRequiredChange}
      handleBooleanFieldRequiredChange={handleBooleanFieldRequiredChange}
      handleRemoveTextField={handleRemoveTextField}
      handleRemoveBooleanField={handleRemoveBooleanField}
      preassessmentQuestions={preassessmentQuestions}
    />
  );
};
