import { Terminal } from "xterm";
import { FitAddon } from "@xterm/addon-fit";
import "./TerminalManager.css";

// Assuming these interfaces are defined somewhere
// interface ITerminal {
//   open(container): void;
//   onData(callback): void;
//   write(data): void;
//   dispose(): void;
// }
// interface IWebSocket {
//   addEventListener(event: string, callback): void;
//   send(data): void;
//   close(): void;
//   readyState: number;
// }

export class TerminalManager {
  constructor(terminalContainer, webSocket, terminal = new Terminal()) {
    this.term = terminal;
    terminalContainer.classList.add("no-scrollbar");
    const fitAddon = new FitAddon();
    this.term.loadAddon(fitAddon);
    this.term.open(terminalContainer);
    fitAddon.fit();
    this.socket = webSocket;

    this.initializeWebSocketListeners();
    this.initializeTerminalListeners();
  }

  initializeWebSocketListeners() {
    this.socket.addEventListener(
      "message",
      this.handleWebSocketMessage.bind(this),
    );
    this.socket.addEventListener("error", this.handleWebSocketError.bind(this));
  }

  handleWebSocketMessage(event) {
    this.term.write(event.data);
  }

  handleWebSocketError(error) {
    console.error("WebSocket error:", error);
  }

  initializeTerminalListeners() {
    this.term.onData(this.handleTerminalData.bind(this));
  }

  handleTerminalData(data) {
    if (this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(data);
    }
  }

  dispose() {
    this.term.dispose();
    this.socket.close();
  }
}
