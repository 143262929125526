import React from "react";
import {
  Form,
  Card,
  Container,
  Row,
  Col,
  Alert,
  Button,
} from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { AICompleteButton } from "../component/AICompleteButton";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import "./CreateJobPostingView.css";

export const CreateJobPostingView = ({
  jobPosting,
  handleChange,
  handleCheckboxChange,
  handleSubmit,
  handleAIGenerate,
  AIGenerating,
  alertMsg,
  handleQuillChange,
  address,
  suggestions,
  handleAddressChange,
  handleSelect,
  textFields,
  booleanFields,
  handleAddTextField,
  handleAddBooleanField,
  handleTextFieldChange,
  handleBooleanFieldChange,
  handleBooleanFieldCorrectAnswerChange,
  handleRemoveTextField,
  handleRemoveBooleanField,
  preassessmentQuestions,
  handleTextFieldRequiredChange,
  handleBooleanFieldRequiredChange,
}) => {
  const jobDescriptionHTML = `
    <h2>About the Job</h2>
    <p>
        We are looking for an enthusiastic, creative, and dynamic individual to join our team as the "Best Job in the World". 
        This role offers the unique opportunity to travel to some of the most beautiful destinations, engage with diverse cultures, 
        and share your experiences with the world.
    </p>
    <br/>
    <h2>Responsibilities</h2>
    <ul>
        <li>Travel to various exotic locations around the globe.</li>
        <li>Document your experiences through blogs, social media, and video content.</li>
        <li>Engage with local communities and participate in cultural activities.</li>
        <li>Collaborate with our marketing team to promote destinations.</li>
        <li>Attend and cover exclusive events and festivals.</li>
    </ul>
    <br/>
    <h2>Requirements</h2>
    <ul>
        <li>Excellent communication and storytelling skills.</li>
        <li>Proficiency in photography and videography.</li>
        <li>Strong social media presence and experience in content creation.</li>
        <li>Passion for travel and adventure.</li>
        <li>Ability to work independently and adapt to different environments.</li>
    </ul>
    <br/>
    <h2>Perks</h2>
    <ul>
        <li>All-expenses-paid travel to some of the most beautiful places on Earth.</li>
        <li>Accommodation in luxury hotels and resorts.</li>
        <li>Competitive salary with performance bonuses.</li>
        <li>Opportunity to meet and network with influencers and celebrities.</li>
        <li>Comprehensive health and travel insurance.</li>
    </ul>
    <br/>
    <p>
        If you believe you have what it takes to embark on this once-in-a-lifetime adventure, we want to hear from you!
    </p>
  `;

  return (
    <Container className="mt-4">
      <Row className="justify-content-center">
        <Col md={8}>
          {alertMsg && (
            <Alert
              variant="danger"
              className="text-center px-2 py-4"
              dismissible
            >
              {alertMsg}
            </Alert>
          )}
          <Card>
            <Card.Body>
              <Card.Title>Create Job Posting</Card.Title>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formTitle" className="mb-3">
                  <Form.Label>
                    Job Title <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    value={jobPosting.title}
                    onChange={handleChange}
                    placeholder="Best Job In The World"
                    required
                  />
                </Form.Group>

                <Form.Group controlId="formLocation" className="mb-3 mt-3">
                  <Form.Label>
                    Location <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="location"
                    value={address}
                    onChange={(e) => handleAddressChange(e.target.value)}
                    required
                  />
                  <div className="autocomplete-dropdown-container">
                    {suggestions.map((suggestion) => (
                      <div
                        key={suggestion.place_id}
                        onClick={() => handleSelect(suggestion.description)}
                        className="suggestion-item"
                      >
                        {suggestion.description}
                      </div>
                    ))}
                  </div>
                </Form.Group>

                <Form.Group controlId="formDescription" className="mb-3">
                  <Form.Label>
                    Job Description <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                  <ReactQuill
                    value={jobPosting.description || jobDescriptionHTML}
                    onChange={(value) =>
                      handleQuillChange("description", value)
                    }
                    theme="snow"
                  />
                </Form.Group>

                <AICompleteButton
                  AIGenerating={AIGenerating}
                  handleAIGenerate={handleAIGenerate}
                />

                <Form.Group controlId="formPreassessment" className="mb-3 mt-3">
                  <Form.Label>Add A SessionScreen Pre-Assessment</Form.Label>
                  <Form.Control
                    as="select"
                    name="preassessment"
                    value={jobPosting.preassessment}
                    onChange={handleChange}
                  >
                    <option value="">None</option>
                    {preassessmentQuestions.map((question) => (
                      <option key={question.id} value={question.id}>
                        {question.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                <Form.Group controlId="formIsActive" className="mb-3 mt-3">
                  <Form.Check
                    type="checkbox"
                    label="Activate Job Opening on Creation?"
                    name="isActive"
                    checked={jobPosting.isActive}
                    onChange={handleCheckboxChange}
                  />
                </Form.Group>

                {textFields.map((field, index) => (
                  <Form.Group
                    controlId={`textField-${index}`}
                    key={index}
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      value={field.name}
                      onChange={(e) =>
                        handleTextFieldChange(index, e.target.value)
                      }
                      placeholder="What makes you most excited about this role?"
                      className="mb-2"
                    />
                    <Form.Check
                      type="checkbox"
                      label="Required"
                      checked={field.required}
                      onChange={() => handleTextFieldRequiredChange(index)}
                      className="mb-2"
                    />
                    <div className="d-flex justify-content-end">
                      <Button
                        variant="outline-danger"
                        onClick={() => handleRemoveTextField(index)}
                        className="d-flex align-items-center"
                      >
                        <AiOutlineMinus />
                        <span className="ms-2">Remove</span>
                      </Button>
                    </div>
                  </Form.Group>
                ))}

                {booleanFields.map((field, index) => (
                  <Form.Group
                    controlId={`booleanField-${index}`}
                    key={index}
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      value={field.name}
                      onChange={(e) =>
                        handleBooleanFieldChange(index, e.target.value)
                      }
                      placeholder="Select the correct option below:"
                      className="mb-2"
                    />
                    <Form.Control
                      as="select"
                      value={field.correctAnswer}
                      onChange={(e) =>
                        handleBooleanFieldCorrectAnswerChange(
                          index,
                          e.target.value,
                        )
                      }
                    >
                      <option value="false">No</option>
                      <option value="true">Yes</option>
                    </Form.Control>
                    <Form.Check
                      type="checkbox"
                      label="Required"
                      checked={field.required}
                      onChange={() => handleBooleanFieldRequiredChange(index)}
                      className="mb-2"
                    />
                    <div className="d-flex justify-content-end">
                      <Button
                        variant="outline-danger"
                        onClick={() => handleRemoveBooleanField(index)}
                        className="d-flex align-items-center"
                      >
                        <AiOutlineMinus />
                        <span className="ms-2">Remove</span>
                      </Button>
                    </div>
                  </Form.Group>
                ))}

                <Form.Group className="d-flex justify-content-end">
                  <Button
                    variant="outline-primary"
                    onClick={handleAddTextField}
                    className="d-flex align-items-center me-2"
                  >
                    <AiOutlinePlus />
                    <span className="ms-2">Add Text Question</span>
                  </Button>
                  <Button
                    variant="outline-primary"
                    onClick={handleAddBooleanField}
                    className="d-flex align-items-center"
                  >
                    <AiOutlinePlus />
                    <span className="ms-2">Add Yes/No Question</span>
                  </Button>
                </Form.Group>

                <div className="d-flex justify-content-end mt-4">
                  <Button variant="primary" type="submit">
                    Create Job Opening
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <br />
    </Container>
  );
};
