import React from "react";
import { Button, Form, Container, InputGroup, Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./CreateSessionView.css"; // Import the CSS file

export const CreateSessionView = ({
  information,
  questions,
  handleChange,
  handleSubmit,
  startDate,
  setStartDate,
  handleCheckboxChange,
}) => {
  return (
    <Container className="mt-5">
      <Row className="justify-content-md-center">
        <Col xs={12} md={6}>
          <h2>Create Your Session Below</h2>
          <Form onSubmit={handleSubmit}>
            <br />
            <Form.Group
              controlId="formBasicCheckbox"
              className="highlight-checkbox"
            >
              <Form.Check
                type="checkbox"
                label="Is This A Pre-Assessment?"
                name="isPreassessment"
                checked={information.isPreassessment}
                onChange={handleCheckboxChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicInput">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Title for the session. Both you and your candidates see this title."
                name="title"
                value={information.title}
                onChange={handleChange}
                required
                minLength="5"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicInput">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Candidate's first name"
                name="firstName"
                value={information.firstName}
                onChange={handleChange}
                required
                minLength="2"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicInput">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Candidate's last name"
                name="lastName"
                value={information.lastName}
                onChange={handleChange}
                required
                minLength="2"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Candidate's email"
                name="email"
                value={information.email}
                onChange={handleChange}
                required
                minLength="5"
              />
            </Form.Group>
            {!information.isPreassessment && (
              <Form.Group className="mb-3" controlId="formBasicVideoChannel">
                <Form.Label>Video Channel Link (Optional)</Form.Label>
                <Form.Control
                  type="url"
                  placeholder="Link to the video channel (e.g., Zoom, Teams, Google Meet)"
                  name="videoChannelLink"
                  value={information.videoChannelLink}
                  onChange={handleChange}
                />
              </Form.Group>
            )}
            <Form.Group className="mb-3 mt-3" controlId="formBasicDropdown">
              <Form.Label>Question</Form.Label>
              <Form.Select
                name="question"
                value={information.questions}
                onChange={handleChange}
                required
              >
                <option value="">Select Question</option>
                {questions.map((q) => (
                  <option key={q.id} value={q.id}>
                    {q.title}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="formDate">
              <Form.Label>Select Date and Time</Form.Label>
              <InputGroup>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  className="form-control"
                />
              </InputGroup>
            </Form.Group>
            <Button variant="primary" type="submit">
              Create
            </Button>
          </Form>
          <br />
        </Col>
      </Row>
    </Container>
  );
};
