import React, { createContext, useState, useContext } from "react";

const BannerContext = createContext();

export const useBanner = () => useContext(BannerContext);

export const BannerProvider = ({ children }) => {
  const [banner, setBanner] = useState(null);

  const showBanner = (message, variant = "success") => {
    setBanner({ message, variant });
  };

  const hideBanner = () => {
    setBanner(null);
  };

  return (
    <BannerContext.Provider value={{ banner, showBanner, hideBanner }}>
      {children}
    </BannerContext.Provider>
  );
};
