import React from "react";
import {
  Container,
  Form,
  Button,
  Row,
  Col,
  Alert,
  Card,
} from "react-bootstrap";
import { Helmet } from "react-helmet";

const FormLabel = ({ children, required }) => (
  <Form.Label>
    {children}
    {required && <span style={{ color: "red" }}> *</span>}
  </Form.Label>
);

export const SignupView = ({
  formData,
  handleChange,
  handleSubmit,
  error,
  token,
}) => {
  return (
    <>
      <Helmet>
        <title>Sign Up</title>
        <meta name="description" content="SessionScreen Sign Up" />
      </Helmet>
      <Container className="mt-5">
        <Row className="justify-content-md-center">
          <Col xs={12} md={8} lg={6}>
            <Card className="shadow-lg p-3 mb-5 bg-white rounded">
              <Card.Body>
                <h2 className="mb-4 text-center">Sign Up</h2>
                {error && (
                  <Alert
                    variant="danger"
                    className="text-center px-2 py-4"
                    dismissible
                  >
                    {error}
                  </Alert>
                )}

                <Form onSubmit={handleSubmit}>
                  {token && token !== "" ? null : (
                    <Form.Group
                      className="mb-3"
                      controlId="formBasicOrganization"
                    >
                      <FormLabel required>Organization Name</FormLabel>
                      <Form.Control
                        type="input"
                        name="organization"
                        value={formData.organization}
                        onChange={handleChange}
                        placeholder="Enter your organization's name"
                        minLength="3"
                        required
                      />
                    </Form.Group>
                  )}
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <FormLabel required>Email Address</FormLabel>
                    <Form.Control
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Enter your work email"
                      required
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <FormLabel required>Password</FormLabel>
                    <Form.Control
                      type="password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      placeholder="Password"
                      minLength="6"
                      required
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword2">
                    <FormLabel required>Confirm Password</FormLabel>
                    <Form.Control
                      type="password"
                      name="password2"
                      value={formData.password2}
                      onChange={handleChange}
                      placeholder="Confirm password"
                      minLength="6"
                      required
                    />
                  </Form.Group>

                  <Form.Group
                    className="mb-3"
                    controlId="formBasicReferralCode"
                  >
                    <FormLabel>Referral Code</FormLabel>
                    <Form.Control
                      type="text"
                      name="referral_code"
                      value={formData.referral_code}
                      onChange={handleChange}
                      placeholder="Enter referral code if you have one"
                    />
                  </Form.Group>

                  <Button variant="primary" type="submit" className="w-100">
                    Sign Up
                  </Button>
                </Form>
                <div className="mt-4 text-center">
                  <Button variant="link" href="/login">
                    Already have an account? Login
                  </Button>
                </div>

                <div className="mt-4 text-center">
                  <Card className="shadow-sm">
                    <Card.Body>
                      <h5>Referral Bonus</h5>
                      <p>
                        Refer an organization and when they first pay for more
                        Interviews on <br /> SessionScreen, both your
                        organization and the one you refer will get 2 free
                        Interviews!
                      </p>
                    </Card.Body>
                  </Card>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
