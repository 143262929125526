import React, { useEffect, useState } from "react";
import axiosInstance from "../axiosConfig";
import { Table, Button } from "react-bootstrap";
import { cancelQuestion } from "../hooks/cancelQuestion";
import { LoadingNotice } from "../component/LoadingNotice";

export const InactiveQuestionsTable = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [inactiveQuestions, setInactiveQuestions] = useState([]);
  const [showInactiveQuestions, setShowInactiveQuestions] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchInactiveQuestions = async () => {
      setIsLoading(true);
      try {
        const response = await axiosInstance.get(
          `${apiBaseUrl}/interviewers/api/v1/inactive-questions`,
        );
        setInactiveQuestions(response.data);
      } catch (error) {
        console.error("Error fetching inactive questions:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInactiveQuestions();
  }, [apiBaseUrl]);

  const toggleInactiveQuestions = () => {
    setShowInactiveQuestions(!showInactiveQuestions);
  };

  if (isLoading) {
    return <LoadingNotice />;
  }

  return (
    <div>
      <Button
        onClick={toggleInactiveQuestions}
        className="mb-3"
        variant="light"
      >
        {showInactiveQuestions
          ? "Hide Pending Questions"
          : "Show Pending Questions"}
      </Button>
      {showInactiveQuestions && (
        <div>
          <h3>Pending Questions</h3>
          {inactiveQuestions.length > 0 ? (
            <Table bordered className="mt-4">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {inactiveQuestions.map((question) => (
                  <tr key={question.id}>
                    <td>{question.title}</td>
                    <td>{question.saving ? "Saving" : "Creating"}</td>
                    <td>
                      {question.saving ? null : (
                        <Button
                          variant="danger"
                          onClick={() => cancelQuestion(question.id)}
                        >
                          Cancel
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No pending questions found.</p>
          )}
        </div>
      )}
    </div>
  );
};
