import React, { useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { PasswordResetConfirmView } from "../views/PasswordResetConfirmView";

export const PasswordResetConfirmContainer = () => {
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const { uidb64, token } = useParams();
  const navigate = useNavigate();

  const handleChange = (e) => {
    setNewPassword(e.target.value);
    setError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const confirmUrl = `${apiBaseUrl}/accounts/api/v1/password-reset-confirm/${uidb64}/${token}/`;
      await axios.post(
        confirmUrl,
        { new_password: newPassword },
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
      setSuccess(true);
      setTimeout(() => {
        navigate("/login");
      }, 3000);
    } catch (error) {
      let errorMessage = "Error: Please try again later.";
      if (error.response && error.response.data) {
        errorMessage = "Error: " + Object.values(error.response.data).join(" ");
      }
      setError(errorMessage);
    }
  };

  return (
    <PasswordResetConfirmView
      newPassword={newPassword}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      error={error}
      success={success}
    />
  );
};
