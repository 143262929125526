import React, { useEffect } from "react";
import {
  Button,
  Form,
  Container,
  Row,
  Col,
  Alert,
  Card,
} from "react-bootstrap";
import { Helmet } from "react-helmet";

const isLocalhost = window.location.hostname === "localhost";

export const LoginView = ({
  credentials,
  handleChange,
  handleSubmit,
  error,
  validationStatus,
  setTurnstileToken,
}) => {
  useEffect(() => {
    const addTurnstileScript = () => {
      if (
        !document.querySelector(
          "script[src='https://challenges.cloudflare.com/turnstile/v0/api.js']",
        )
      ) {
        const script = document.createElement("script");
        script.src = "https://challenges.cloudflare.com/turnstile/v0/api.js";
        script.async = true;
        document.body.appendChild(script);

        script.onload = () => {
          window.turnstileCallback = function (token) {
            setTurnstileToken(token);
          };
          // Render the Turnstile widget
          window.turnstile.render(".cf-turnstile", {
            sitekey: isLocalhost
              ? "1x00000000000000000000AA"
              : "0x4AAAAAAAcZKtmIkRvpbgAu",
            callback: window.turnstileCallback,
          });
        };

        return script;
      }
      return null;
    };

    const script = addTurnstileScript();

    return () => {
      if (script) {
        document.body.removeChild(script);
      }
      if (window.turnstile) {
        window.turnstile.remove(".cf-turnstile");
      }
    };
  }, [setTurnstileToken]);

  return (
    <>
      <Helmet>
        <title>Login</title>
        <meta name="description" content="SessionScreen Login" />
      </Helmet>
      <Container className="mt-5">
        {validationStatus === "success" && (
          <Alert
            variant="success"
            className="text-center px-2 py-4"
            dismissible
          >
            Email validated successfully!
          </Alert>
        )}
        {validationStatus === "failure" && (
          <Alert variant="danger" className="text-center px-2 py-4" dismissible>
            Failed to validate email.
          </Alert>
        )}
        <Row className="justify-content-md-center">
          <Col xs={12} md={8} lg={6}>
            <Card className="shadow-lg p-3 mb-5 bg-white rounded">
              <Card.Body>
                <h2 className="mb-4 text-center">Login</h2>

                {error && (
                  <Alert
                    variant="danger"
                    className="text-center px-2 py-4"
                    dismissible
                  >
                    {error}
                  </Alert>
                )}
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      name="emailsfasdfasd"
                      value={credentials.emailsfasdfasd}
                      onChange={handleChange}
                      required
                      minLength="1"
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      name="passwordsdfsdfadfa"
                      value={credentials.passwordsdfsdfadfa}
                      onChange={handleChange}
                      required
                      minLength="1"
                    />
                  </Form.Group>

                  {/* Honeypot fields - hidden from view */}
                  <Form.Group controlId="formHoneyEmail" className="d-none">
                    <Form.Label>Leave this field empty</Form.Label>
                    <Form.Control
                      type="text"
                      name="email"
                      value={credentials.email}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="formHoneyPassword" className="d-none">
                    <Form.Label>Leave this field empty</Form.Label>
                    <Form.Control
                      type="text"
                      name="password"
                      value={credentials.password}
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <div className="text-center my-3">
                    <div
                      className="cf-turnstile"
                      data-sitekey={
                        isLocalhost
                          ? "1x00000000000000000000AA"
                          : "0x4AAAAAAAcZKtmIkRvpbgAu"
                      }
                      data-callback="turnstileCallback"
                    ></div>
                  </div>

                  <Button
                    variant="primary"
                    type="submit"
                    className="w-100 mt-3"
                  >
                    Login
                  </Button>
                </Form>
                <div className="mt-4 text-center">
                  <Button variant="link" href="/signup">
                    Don't have an account? Sign Up
                  </Button>
                  <br />
                  <Button variant="link" href="/password-reset">
                    Forgot your password? Reset Password
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
