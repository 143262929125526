import React from "react";
import { PricingContainer } from "./PricingContainer";

export const PricingOverviewContainer = () => {
  const header = "No subscriptions. No credit card required.";
  const subtitle =
    "Start with 3 free interviews and 1 custom question. Purchase more as needed.";
  const callToAction = "Get Started For Free";

  return (
    <PricingContainer
      header={header}
      subtitle={subtitle}
      callToAction={callToAction}
      includeTerminology={true}
      showCallToActionButton={true}
      showStripeCheckoutButton={false}
    />
  );
};
