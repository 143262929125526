import React from "react";

export const EmailVerificationNotice = () => (
  <>
    <p>It looks like your email address is not verified.</p>
    <p>
      Please click the verification link in your email and refresh the page.
    </p>
  </>
);
