import React from "react";
import { Button, Form, Container, Row, Col } from "react-bootstrap";

export const QuestionUpdateView = ({
  information,
  handleChange,
  handleSubmit,
}) => {
  return (
    <Container className="mt-5">
      <Row className="justify-content-md-center">
        <Col xs={12} md={6}>
          <h2>Update Question</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicInput">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Title of your question."
                name="title"
                value={information.title}
                onChange={handleChange}
                required
                minLength="5"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicInput">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                type="text"
                placeholder="Description of your question."
                name="description"
                value={information.description}
                onChange={handleChange}
                required
                minLength="5"
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Update
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};
