import React from "react";
import {
  Button,
  Form,
  Container,
  Row,
  Col,
  Alert,
  Card,
} from "react-bootstrap";

export const PasswordResetConfirmView = ({
  newPassword,
  handleChange,
  handleSubmit,
  error,
  success,
}) => {
  return (
    <Container className="mt-5">
      <Row className="justify-content-md-center">
        <Col xs={12} md={8} lg={6}>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card.Body>
              <h2 className="mb-4 text-center">Reset Password</h2>
              {success && (
                <Alert
                  variant="success"
                  className="text-center px-2 py-4"
                  dismissible
                >
                  Password has been reset successfully!
                </Alert>
              )}
              {error && (
                <Alert
                  variant="danger"
                  className="text-center px-2 py-2"
                  dismissible
                >
                  {error}
                </Alert>
              )}
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter new password"
                    name="newPassword"
                    value={newPassword}
                    onChange={handleChange}
                    required
                    minLength="1"
                  />
                </Form.Group>

                <Button variant="primary" type="submit" className="w-100">
                  Reset Password
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
