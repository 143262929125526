import React from "react";
import { Container, Form, Button, Row, Col, Alert } from "react-bootstrap";
import { LoadingNotice } from "../component/LoadingNotice";
import { EmailVerificationNotice } from "../component/EmailVerificationNotice";

export const GradeSessionView = ({
  handleChange,
  sessionTitle,
  handleSubmit,
  error,
  isLoading,
  authLoading,
  emailVerified,
}) => {
  if (isLoading || authLoading) {
    return <LoadingNotice />;
  }

  if (!emailVerified) {
    return <EmailVerificationNotice />;
  }
  return (
    <Container className="mt-5">
      {error && (
        <Alert variant="danger" className="text-center px-2 py-4" dismissible>
          {error}
        </Alert>
      )}
      <Row className="justify-content-md-center">
        <Col xs={12} md={6}>
          <h2>Grading Session: {sessionTitle}</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicGrade">
              <Form.Label>Grade</Form.Label>
              <Form.Control
                type="input"
                name="grade"
                onChange={handleChange}
                placeholder="Please grade the session (a score out of 10)"
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicInput">
              <Form.Label>Notes</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                type="text"
                placeholder="Please add some notes of your session."
                name="notes"
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
          <br />
        </Col>
      </Row>
    </Container>
  );
};
