import axiosInstance from "../../axiosConfig";

export const startSession = (apiBaseHostname, sessionId, token) => {
  let url = `${apiBaseHostname}/interviewers/api/v1/question-attempts/start/${sessionId}`;
  if (token !== null && token !== "") {
    url += `?token=${token}`;
  }
  return axiosInstance.put(url);
};

export const checkSessionReady = (apiBaseHostname, sessionId, token) => {
  let url = `${apiBaseHostname}/interviewers/api/v1/question-attempts/ready/${sessionId}`;
  if (token !== null && token !== "") {
    url += `?token=${token}`;
  }
  return axiosInstance.get(url);
};
