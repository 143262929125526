import { useEffect, useRef, useState } from "react";
import { TerminalManager } from "../containers/TerminalManager";

export const useWebSocketConnection = (
  isSessionReady,
  apiBaseHostname,
  sessionId,
  terminalRef,
  websocketPath,
  uuid,
) => {
  const [isConnected, setConnected] = useState(false);
  const terminalManagerRef = useRef(null);

  useEffect(() => {
    if (isSessionReady && !isConnected) {
      const protocol =
        window.location.protocol === "https:" ? "wss://" : "ws://";
      const fixedApiBaseHostname = apiBaseHostname.replace(/^https?:\/\//, "");
      const socketUrl = `${protocol}${fixedApiBaseHostname}/ws/execute_command/${websocketPath}/${uuid}/`;
      const socket = new WebSocket(socketUrl);

      socket.onopen = () => {
        setConnected(true);
        if (terminalRef.current) {
          terminalManagerRef.current = new TerminalManager(
            terminalRef.current,
            socket,
          );
        }
      };

      socket.onclose = () => {
        if (terminalManagerRef.current) {
          terminalManagerRef.current.dispose();
        }
      };

      return () => {
        socket.close();
        if (terminalManagerRef.current) {
          terminalManagerRef.current.dispose();
        }
      };
    }
    // eslint-disable-next-line
  }, [isSessionReady, apiBaseHostname, sessionId, terminalRef]); // do not include isConnected

  return { isConnected };
};
