import React, { useState } from "react";
import { Container, Button, Row, Col } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Helmet } from "react-helmet";
import "./LandingPageView.css";
import ScenarioSection from "../component/ScenarioSection";

export const LandingPageView = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleImageClick = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const images = [
    {
      src: "step1.png",
      title: "Step 1: Start a New Computer",
      text: "Easily create a new computer (e.g., Windows, Linux) right from your browser.",
      alt: "Start a New Computer",
    },
    {
      src: "step2.png",
      title: "Step 2: Set It Up for Interviews",
      text: "Configure the computer in your browser with the tools and environment needed for interviews.",
      alt: "Set It Up for Interviews",
    },
    {
      src: "step3.png",
      title: "Step 3: Send Candidates Your Interview Scenario",
      text: "Send candidates your custom interview scenarios.",
      alt: "Send Candidates Your Interview Scenario",
    },
    {
      src: "step4.png",
      title: "Step 4: Candidates Work On Your Scenarios",
      text: "Candidates work on your scenarios, either in pass/fail pre-screens or in one-on-one collaborative interviews.",
      alt: "Candidates Work On Your Scenarios",
    },
    {
      src: "dashboard.png",
      title: "Simple Interface",
      text: "Easily organize and keep track of all your Interviews and hands-on Questions.",
      alt: "Simple Interface",
    },
    {
      src: "organization_page.png",
      title: "Access Control",
      text: "Your custom Interview Questions remain private within your own organization.",
      alt: "Access Control",
    },
  ];

  return (
    <>
      <Helmet>
        <title>SessionScreen | Hands-On Interview Questions</title>
        <meta
          name="description"
          content="SessionScreen helps you create hands-on interview questions for DevOps and Linux interviews. Verify candidates' practical skills before interviewing them."
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "CreativeWorkSeries",
            name: "SessionScreen",
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: "4.5",
              reviewCount: "150",
            },
          })}
        </script>
      </Helmet>

      <Container className="my-5">
        <Row className="align-items-center text-center">
          <Col md={12}>
            <h1>Having Trouble Finding Qualified Candidates?</h1>
            <p />
            <h5 className="lead">
              With SessionScreen, you can verify candidates' practical skills
              before interviewing them.
            </h5>
            <h5 className="lead">
              Create your first three hands-on pre-screens for free.
            </h5>
            <h5 className="lead">No subscriptions. No credit card required.</h5>
            <br />
            <LinkContainer to="/signup">
              <Button variant="primary" size="lg">
                Get Started For Free
              </Button>
            </LinkContainer>
          </Col>
        </Row>
      </Container>

      <hr />

      <Container className="my-5" id="demo">
        <Row className="text-center">
          <Col>
            <Row>
              <h3>How It Works:</h3>
              <p className="lead">
                Create a hands-on scenario in the browser. If you want to make
                your scenario an automated pre-screen, simply add a{" "}
                <code>sessionscreen</code> script to a designated location.
              </p>
            </Row>
          </Col>
        </Row>
        <Row className="text-center">
          {images.slice(0, 4).map((step, index) => (
            <Col
              key={index}
              md={6}
              className={`my-4 step-container ${expandedIndex === index ? "expanded" : ""}`}
              onClick={() => handleImageClick(index)}
            >
              <div className="step-content img-container">
                <img
                  src={`${process.env.PUBLIC_URL}/${step.src}`}
                  alt={step.alt}
                  className="img-fluid step-image"
                />
                <h4>{step.title}</h4>
                <p className="lead">{step.text}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>

      <hr />

      {images.slice(4, 6).map((step, index) => (
        <Container
          className={`my-5 ${expandedIndex === index + 4 ? "expanded" : ""}`}
          key={index + 4}
        >
          <Row className="align-items-center text-center">
            <Col md={6}>
              <h3>{step.title}</h3>
              <p className="lead">{step.text}</p>
            </Col>
            <Col
              md={6}
              className="mb-3"
              onClick={() => handleImageClick(index + 4)}
            >
              <img
                src={`${process.env.PUBLIC_URL}/${step.src}`}
                alt={step.alt}
                className="img-fluid misc-image"
              />
            </Col>
          </Row>
          <hr />
        </Container>
      ))}

      <ScenarioSection />

      <hr />

      <br />
      <br />
      <br />

      <Container fluid className="text-center">
        <h3>
          Need Assistance?{" "}
          <a
            href="https://support.sessionscreen.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            We Have Dedicated Support on Discord
          </a>
          .
        </h3>
        <br />
      </Container>

      <br />
      <br />
      <hr />
      <br />

      <Container fluid className="text-center bg-light p-5 landing-container">
        <h2>Ready to transform your hiring process?</h2>
        <LinkContainer to="/signup">
          <Button variant="primary" size="lg">
            Get Started For Free
          </Button>
        </LinkContainer>
        <p className="mt-4 lead">
          Would you like to book a demo? Please{" "}
          <LinkContainer to="/contact-us">
            <a href="/contact-us">Contact Us</a>
          </LinkContainer>
          .
        </p>
      </Container>
      <br />
    </>
  );
};
