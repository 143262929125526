import React from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../axiosConfig";
import { useBanner } from "../BannerContext";
import { Col, Button } from "react-bootstrap";

export const CancelCreateQuestionButton = ({ questionId, isQuestionReady }) => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const { showBanner } = useBanner();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const questionUrl = `${apiBaseUrl}/interviewers/api/v1/questions/cancel/${questionId}`;
      await axiosInstance.delete(questionUrl);
      showBanner(`Question successfully cancelled`, "info");
      navigate(`/dashboard/questions`);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 404) {
          showBanner("The question no longer exists.", "warning");
          navigate(`/dashboard/questions`);
        } else if (error.response.status === 400) {
          showBanner(
            "Question not cancelled. The question may be in the process of being saved.",
            "warning",
          );
          navigate(`/dashboard/questions`);
        } else {
          showBanner("Failed to cancel question. Please try again.", "danger");
          console.error(
            "Error saving question:",
            error.response ? error.response.data : error,
          );
        }
      } else {
        showBanner("Failed to cancel question. Please try again.", "danger");
        console.error(
          "Error saving question:",
          error.response ? error.response.data : error,
        );
      }
    }
  };

  return (
    <Col className="d-flex justify-content-center">
      <Button variant="danger" type="submit" onClick={handleSubmit}>
        <div>Cancel</div>
      </Button>
    </Col>
  );
};
