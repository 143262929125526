import React from "react";
import { Dropdown, Table, OverlayTrigger, Tooltip } from "react-bootstrap";
import "./DataTable.css";

export const DataTable = ({
  data,
  columns,
  onContextMenu,
  contextMenu,
  contextMenuRef,
  contextMenuItems,
}) => {
  return (
    <>
      <Table striped bordered hover size="sm" className="shadow">
        <thead className="bg-primary text-white">
          <tr>
            {columns.map((column, index) => (
              <th key={index}>{column.header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, rowIndex) => (
            <OverlayTrigger
              key={rowIndex}
              overlay={<Tooltip>Right-click for more options</Tooltip>}
            >
              <tr
                key={rowIndex}
                className="table-row"
                onContextMenu={(e) => onContextMenu(e, item, rowIndex)}
              >
                {columns.map((column, colIndex) => (
                  <td key={colIndex}>{item[column.field].toString()}</td>
                ))}
              </tr>
            </OverlayTrigger>
          ))}
        </tbody>
      </Table>

      {contextMenu.show && (
        <Dropdown.Menu
          ref={contextMenuRef}
          show
          style={{
            position: "absolute",
            top: contextMenu.y,
            left: contextMenu.x,
            zIndex: 1000,
          }}
        >
          {contextMenuItems.map((menuItem, index) => (
            <Dropdown.Item
              key={index}
              onClick={() => menuItem.onClick(contextMenu.rowData)}
            >
              {menuItem.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      )}
    </>
  );
};
