import React from "react";
import { Spinner } from "react-bootstrap";

export const LoadingNotice = () => (
  <>
    <center>
      <Spinner animation="border" role="status"></Spinner>
    </center>
  </>
);
