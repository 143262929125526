import React from "react";
import { Alert, Button, Form, Container, Row, Col } from "react-bootstrap";

export const StartCreateQuestionView = ({
  information,
  questionTypes,
  handleChange,
  handleSubmit,
  error,
}) => {
  return (
    <Container className="mt-5">
      {error && (
        <Alert variant="danger" className="text-center px-2 py-4" dismissible>
          {error}
        </Alert>
      )}
      <Row className="justify-content-md-center">
        <Col xs={12} md={6}>
          <h2>Create Question</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicInput">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Title of your question."
                name="title"
                value={information.title}
                onChange={handleChange}
                required
                minLength="5"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicInput">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                type="text"
                placeholder="Description of your question."
                name="description"
                value={information.description}
                onChange={handleChange}
                required
                minLength="5"
              />
              <Form.Group className="mb-3" controlId="formBasicDropdown">
                <Form.Label>Computer Type</Form.Label>
                <Form.Select
                  name="type"
                  value={information.type}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Computer Type</option>
                  {questionTypes.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.type_name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Form.Group>
            <Button variant="primary" type="submit">
              Next
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};
