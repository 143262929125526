import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../axiosConfig";
import { useCommonContainerLogic } from "./useCommonContainerLogic";
import { QuestionUpdateView } from "../views/QuestionUpdateView";

export const QuestionUpdateContainer = () => {
  const [information, setInformation] = useState({
    title: "",
    description: "",
    type: 0,
  });
  const navigate = useNavigate();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  useCommonContainerLogic();
  const { questionId } = useParams();

  useEffect(() => {
    const fetchQuestionTypes = async () => {
      try {
        const response = await axiosInstance.get(
          `${apiBaseUrl}/interviewers/api/v1/questions/${questionId}`,
        );
        setInformation({
          title: response.data.title,
          description: response.data.description,
          type: response.data.type,
        });
      } catch (error) {
        console.error("Error fetching question question:", error);
      }
    };

    fetchQuestionTypes();
  }, [apiBaseUrl, questionId]);

  const handleChange = (e) => {
    setInformation({ ...information, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const questionUrl = `${apiBaseUrl}/interviewers/api/v1/questions/${questionId}`;
      await axiosInstance.put(questionUrl, {
        ...information,
      });
      navigate("/dashboard/questions");
    } catch (error) {
      console.error(
        "Error updating question:",
        error.response ? error.response.data : error,
      );
    }
  };

  return (
    <QuestionUpdateView
      information={information}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
    />
  );
};
