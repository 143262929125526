export const poll = (
  fn,
  validate,
  interval = 2000,
  scheduleFn = setTimeout,
) => {
  return new Promise((resolve, reject) => {
    const executePoll = () => {
      fn()
        .then(validate)
        .then((result) => {
          if (result === true) {
            resolve();
          } else {
            scheduleFn(executePoll, interval);
          }
        })
        .catch(reject);
    };
    executePoll();
  });
};
