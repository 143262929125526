import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaFileExcel } from "react-icons/fa";
import { FaLinux, FaWindows } from "react-icons/fa6";
import {
  SiKubernetes,
  SiDjango,
  SiPowershell,
  SiPostgresql,
  SiSpring,
} from "react-icons/si";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";

const scenarios = [
  {
    title: "Excel Spreadsheets",
    icon: <FaFileExcel size={48} alt="Excel Spreadsheets" />,
    text: "Can your candidates clean up and manage data in Excel?",
  },
  {
    title: "Windows Desktops",
    icon: <FaWindows size={48} alt="Windows Desktops" />,
    text: "Can your candidates fix Windows desktops?",
  },
  {
    title: "Kubernetes Clusters",
    icon: <SiKubernetes size={48} alt="Kubernetes Clusters" />,
    text: "Can your candidates debug and manage Kubernetes clusters?",
  },
  {
    title: "Django Applications",
    icon: <SiDjango size={48} alt="Django Applications" />,
    text: "Can your candidates debug and fix issues in Django applications?",
  },
  {
    title: "Powershell Scripts",
    icon: <SiPowershell size={48} alt="Powershell Scripts" />,
    text: "Do your candidates know how to script with Powershell?",
  },
  {
    title: "Linux Servers",
    icon: <FaLinux size={48} alt="Linux Servers" />,
    text: "Do your candidates know how to debug a Linux performance problem?",
  },
  {
    title: "Postgres Databases",
    icon: <SiPostgresql size={48} alt="Postgres Databases" />,
    text: "Do your candidates know how to perform a complex database migration?",
  },
  {
    title: "Spring DI",
    icon: <SiSpring size={48} alt="Spring DI" />,
    text: "Do your candidates know how to fix tricky DI problems?",
  },
];

const ScenarioSection = () => (
  <Container className="my-5">
    <Row className="align-items-center text-center">
      <Col>
        <h3>
          Screen Your Candidates With <u>Any</u> Hands-On Computer-Based
          Scenario You Can Think Of, Including DevOps and Linux Interview
          Questions:
        </h3>
      </Col>
    </Row>
    <br />
    <br />
    <Swiper
      modules={[Autoplay]}
      spaceBetween={30}
      slidesPerView={3}
      loop={true}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
    >
      {scenarios.map((scenario, index) => (
        <SwiperSlide key={index}>
          <div className="text-center">
            {scenario.icon}
            <h5>{scenario.title}</h5>
            <p>{scenario.text}</p>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  </Container>
);

export default ScenarioSection;
