import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

export const TermsOfServiceView = () => {
  return (
    <>
      <Container className="my-5">
        <Row className="align-items-center text-center">
          <Col md={12}>
            <h1>Terms of Service</h1>
            <p>Last updated: June 27, 2024</p>
            <br />
            <p>
              Welcome to PraxisGrid, Inc. By accessing or using our website
              https://sessionscreen.com ("Site") and our services, you agree to
              be bound by these Terms of Service ("Terms"). If you do not agree
              to these Terms, please do not use our Site and services.
            </p>
            <br />
          </Col>
        </Row>
      </Container>

      <Container className="my-5">
        <Row>
          <Col md={12}>
            <h2>1. Use of Services</h2>
            <p>
              You agree to use our services only for lawful purposes and in
              accordance with these Terms. You agree not to use our services:
            </p>
            <ul>
              <li>
                For any unlawful or fraudulent purpose, or to engage in any
                conduct that is disruptive, harmful, or otherwise inappropriate.
              </li>
              <li>
                To impersonate or attempt to impersonate SessionScreen, a
                SessionScreen employee, another user, or any other person or
                entity.
              </li>
              <li>
                To create multiple accounts or organizations to exploit free
                interviews or any other promotional offers.
              </li>
              <li>
                To upload, transmit, or distribute any viruses, worms, or any
                software intended to damage or alter a computer system or data.
              </li>
              <li>
                To interfere with or disrupt the integrity or performance of our
                services or the data contained therein.
              </li>
              <li>
                To attempt to gain unauthorized access to our services, user
                accounts, or computer systems or networks connected to our
                services.
              </li>
              <li>
                To engage in any activity that could harm or cause distress to
                other users or damage the reputation of PraxisGrid, Inc. or
                SessionScreen.
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h2>2. Account Security</h2>
            <p>
              You are responsible for maintaining the confidentiality of your
              account and password, and for restricting access to your computer.
              You agree to accept responsibility for all activities that occur
              under your account or password. You must notify us immediately
              upon becoming aware of any breach of security or unauthorized use
              of your account. PraxisGrid, Inc. is not liable for any loss or
              damage arising from your failure to comply with this security
              obligation.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h2>3. Payment and Billing</h2>
            <p>
              You agree to provide accurate and complete payment information and
              to update this information as necessary. You agree to pay all
              charges incurred by users under your account, including any
              applicable taxes and fees. All payments are non-refundable unless
              otherwise stated.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h2>4. Termination</h2>
            <p>
              We reserve the right to suspend or terminate your account and
              access to our services at our sole discretion, without notice, for
              conduct that we believe violates these Terms or is otherwise
              harmful to other users of the Site, us, or third parties. In the
              event of termination, your right to use our services will
              immediately cease, and all data associated with your account may
              be deleted.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h2>5. Intellectual Property</h2>
            <p>
              The content, features, and functionality on our Site, including
              but not limited to text, graphics, logos, and software, are owned
              by PraxisGrid, Inc. and are protected by international copyright,
              trademark, patent, trade secret, and other intellectual property
              or proprietary rights laws. You may not copy, modify, distribute,
              sell, or lease any part of our services or included software, nor
              may you reverse engineer or attempt to extract the source code of
              that software, unless laws prohibit those restrictions or you have
              our written permission.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h2>6. Limitation of Liability</h2>
            <p>
              To the maximum extent permitted by applicable law, in no event
              shall PraxisGrid, Inc., its affiliates, directors, employees, or
              agents be liable for any indirect, punitive, incidental, special,
              consequential, or exemplary damages, including without limitation
              damages for loss of profits, goodwill, use, data or other
              intangible losses, that result from the use of, or inability to
              use, this Site or our services. Under no circumstances will
              PraxisGrid, Inc. be responsible for any damage, loss, or injury
              resulting from hacking, tampering, or other unauthorized access or
              use of our services or your account or the information contained
              therein. Your sole remedy for dissatisfaction with the services is
              to stop using the services.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h2>7. Indemnification</h2>
            <p>
              You agree to defend, indemnify, and hold harmless PraxisGrid,
              Inc., its affiliates, directors, employees, and agents from and
              against any and all claims, damages, obligations, losses,
              liabilities, costs, or debt, and expenses (including but not
              limited to attorney's fees) arising from your use of and access to
              the Site and services, or your violation of these Terms.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h2>8. Governing Law</h2>
            <p>
              These Terms shall be governed and construed in accordance with the
              laws of the jurisdiction in which PraxisGrid, Inc. operates,
              without regard to its conflict of law provisions. Any dispute
              arising from these Terms or your use of our services will be
              handled in the competent courts of that jurisdiction.
            </p>
            <p>
              For users residing in the European Union, you may also refer to
              the online dispute resolution platform provided by the European
              Commission.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h2>9. Changes to These Terms</h2>
            <p>
              We may modify these Terms at any time by posting the revised terms
              on our Site. It is your responsibility to review these Terms
              periodically. Your continued use of the Site following the posting
              of changes constitutes your acceptance of such changes. If you do
              not agree to the new terms, you must stop using the services.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h2>10. Contact Us</h2>
            <p>
              If you have any questions about these Terms, please contact us at
              support@sessionscreen.com.
            </p>
          </Col>
        </Row>
      </Container>

      <Container fluid className="text-center bg-light p-5">
        <h2>Have Questions?</h2>
        <LinkContainer to="/contact-us">
          <button className="btn btn-primary btn-lg">Contact Us</button>
        </LinkContainer>
      </Container>
      <br />
    </>
  );
};
