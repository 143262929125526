import axiosInstance from "../../axiosConfig";

export const startQuestion = (apiBaseHostname, sessionId) => {
  const startSessionUrl = `${apiBaseHostname}/interviewers/api/v1/questions/create/start/${sessionId}`;
  return axiosInstance.put(startSessionUrl);
};

export const checkQuestionReady = (apiBaseHostname, sessionId) => {
  const checkReadyUrl = `${apiBaseHostname}/interviewers/api/v1/questions/create/ready/${sessionId}`;
  return axiosInstance.get(checkReadyUrl);
};
