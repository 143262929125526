import React, { useEffect, useState } from "react";
import axiosInstance from "../axiosConfig";
import { Table } from "react-bootstrap";

export const JobOpeningStatus = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [activeOpeningsCount, setActiveOpeningsCount] = useState(0);
  const [activeApplicantsCount, setActiveApplicantsCount] = useState(0);

  useEffect(() => {
    const fetchOverallJobApplicationStatus = async () => {
      try {
        const response = await axiosInstance.get(
          `${apiBaseUrl}/jobs/api/v1/job-application/overall-status`,
        );
        setActiveOpeningsCount(response.data.active_jobs_count);
        setActiveApplicantsCount(response.data.active_applications_count);
      } catch (error) {
        console.error("Error fetching inactive questions:", error);
      }
    };

    fetchOverallJobApplicationStatus();
  }, [apiBaseUrl]);
  return (
    <Table bordered>
      <thead>
        <tr>
          <th>Total Number of Active Openings: </th>
          <th>Total Number of Active Candidates:</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{activeOpeningsCount}</td>
          <td>{activeApplicantsCount}</td>
        </tr>
      </tbody>
    </Table>
  );
};
