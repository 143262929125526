import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { SignupView } from "../views/SignupView";
import { useBanner } from "../BannerContext";
import { useLocation } from "react-router-dom";

export const SignupContainer = () => {
  const { showBanner } = useBanner();
  const [formData, setFormData] = useState({
    email: "",
    organization: "",
    password: "",
    password2: "",
    first_name: "",
    last_name: "",
    referral_code: "",
  });

  const [error, setError] = useState("");

  const navigate = useNavigate();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const tokenParam = params.get("token");
  let token = "";
  if (tokenParam !== null && tokenParam !== undefined && tokenParam !== "") {
    token = tokenParam;
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setError("");
  };

  const canSubmit = () => {
    if (
      (!token && !formData.organization) ||
      !formData.email ||
      !formData.password ||
      !formData.password2
    ) {
      setError("Please fill in all fields.");
      return false;
    }
    if (formData.password !== formData.password2) {
      setError("Passwords do not match.");
      return false;
    }
    if (formData.password.length < 6) {
      setError("Password must be at least 6 characters long.");
      return false;
    }
    if (!token && formData.organization.length < 3) {
      setError("Organization must be at least 3 characters long.");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!canSubmit()) return;

    try {
      const signupUrl = `${apiBaseUrl}/accounts/api/v1/register/`;
      const formDataClone = { ...formData };
      if (token && token !== "") {
        formDataClone.token = token;
        delete formDataClone.organization;
      }
      await axios.post(signupUrl, formDataClone);
      showBanner(
        "An account verification email was sent to you. Please verify your account before continuing",
        "success",
      );
      navigate("/login");
    } catch (error) {
      let errorMessage = "Signup error: Please try again later.";
      if (error.response && error.response.data) {
        errorMessage =
          "Signup error: " + Object.values(error.response.data).join(" ");
      }
      setError(errorMessage);
    }
  };

  return (
    <SignupView
      formData={formData}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      error={error}
      token={token}
    />
  );
};
