import { useStartProcess } from "./useStartProcess";
import {
  startQuestion,
  checkQuestionReady,
} from "./utils/questionCreationService";

export const useQuestionStart = (apiBaseHostname, questionId, token) => {
  const usp = useStartProcess(
    apiBaseHostname,
    questionId,
    startQuestion,
    checkQuestionReady,
    token,
  );
  return usp.isReady;
};
