import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CreateSessionView } from "../views/CreateSessionView";
import axiosInstance from "../axiosConfig";
import { useCommonContainerLogic } from "./useCommonContainerLogic";

export const CreateSessionContainer = () => {
  const [information, setInformation] = useState({
    title: "",
    firstName: "",
    lastName: "",
    email: "",
    question: "",
    videoChannelLink: "",
    isPreassessment: false,
  });
  const [questions, setQuestions] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  useCommonContainerLogic();

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await axiosInstance.get(
          `${apiBaseUrl}/interviewers/api/v1/questions`,
        );
        const filteredQuestions = information.isPreassessment
          ? response.data.filter((q) => q.contains_script)
          : response.data;
        setQuestions(filteredQuestions);
      } catch (error) {
        console.error("Error fetching question types:", error);
      }
    };

    fetchQuestions();
  }, [apiBaseUrl, information.isPreassessment]);

  const handleChange = (e) => {
    setInformation({ ...information, [e.target.name]: e.target.value });
  };

  const handleCheckboxChange = (e) => {
    setInformation({ ...information, [e.target.name]: e.target.checked });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const questionAttemptsUrl = `${apiBaseUrl}/interviewers/api/v1/question-attempts`;
      const data = {
        candidate: {
          first_name: information.firstName,
          last_name: information.lastName,
          email: information.email,
        },
        title: information.title,
        question: information.question,
        date: startDate.toISOString(),
        vc_link: information.videoChannelLink,
        is_preassessment: information.isPreassessment,
      };
      await axiosInstance.post(questionAttemptsUrl, data);
      navigate("/dashboard/sessions");
    } catch (error) {
      console.error(
        "Error creating session:",
        error.response ? error.response.data : error,
      );
    }
  };

  return (
    <CreateSessionView
      information={information}
      questions={questions}
      handleChange={handleChange}
      handleCheckboxChange={handleCheckboxChange}
      handleSubmit={handleSubmit}
      startDate={startDate}
      setStartDate={setStartDate}
    />
  );
};
