import React from "react";
import {
  Container,
  Form,
  Button,
  Row,
  Col,
  Alert,
  Card,
} from "react-bootstrap";
import { Helmet } from "react-helmet";

export const ContactUsView = ({
  handleChange,
  handleSubmit,
  error,
  success,
  formData,
}) => {
  return (
    <>
      <Helmet>
        <title>Contact Us</title>
        <meta name="description" content="SessionScreen Contact Us" />
      </Helmet>
      <Container className="mt-5">
        {success && (
          <Alert
            variant="success"
            className="text-center px-2 py-4"
            dismissible
          >
            {success}
          </Alert>
        )}
        {error && (
          <Alert variant="danger" className="text-center px-2 py-4" dismissible>
            {error}
          </Alert>
        )}
        <Row className="justify-content-md-center">
          <Col xs={12} md={8} lg={6}>
            <Card className="shadow-lg p-3 mb-5 bg-white rounded">
              <Card.Body>
                <h2 className="mb-4 text-center">Contact Us!</h2>
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3" controlId="formBasicSubject">
                    <Form.Label>Subject</Form.Label>
                    <Form.Control
                      type="input"
                      name="subject"
                      value={formData.subject}
                      onChange={handleChange}
                      placeholder="What is SessionScreen?"
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="example@email.com"
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicMessage">
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      placeholder="Please tell me more about SessionScreen!"
                      required
                    />
                  </Form.Group>

                  <Button variant="primary" type="submit" className="w-100">
                    Submit
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
