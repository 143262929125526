import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Spinner,
  ListGroup,
  Button,
} from "react-bootstrap";
import axiosInstance from "../axiosConfig";
import { useNavigate, useParams } from "react-router-dom";

export const SessionDashboardContainer = () => {
  const { sessionId } = useParams();
  const [sessionDetails, setSessionDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSessionDetails = async () => {
      try {
        const response = await axiosInstance.get(
          `${apiBaseUrl}/interviewers/api/v1/question-attempt/${sessionId}`,
        );
        setSessionDetails(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching session details:", error);
        setLoading(false);
      }
    };

    fetchSessionDetails();
  }, [apiBaseUrl, sessionId]);

  if (loading) {
    return (
      <Container
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="border" />
      </Container>
    );
  }

  if (!sessionDetails) {
    return (
      <Container
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <h2>No Session Details Found</h2>
      </Container>
    );
  }

  const {
    id,
    title,
    score,
    notes,
    completed,
    closed,
    vc_link,
    question_details,
    candidate,
    date,
  } = sessionDetails;

  const interviewDate = date ? new Date(date).toLocaleString() : "";

  return (
    <Container>
      <Row className="my-4">
        <Col>
          <h2 className="text-center">{title}</h2>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 8, offset: 2 }}>
          <Card>
            <Card.Body>
              <Row>
                <Col md={6}>
                  <ListGroup variant="flush">
                    <ListGroup.Item>
                      <strong>Candidate First Name:</strong>{" "}
                      {candidate.first_name}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <strong>Candidate Email:</strong> {candidate.email}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <strong>Interview Date:</strong> {interviewDate}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <strong>Graded:</strong> {completed ? "Yes" : "No"}
                    </ListGroup.Item>
                    {completed && (
                      <ListGroup.Item>
                        <strong>Interview Grade:</strong>{" "}
                        {score + "/10" ?? "N/A"}
                      </ListGroup.Item>
                    )}
                  </ListGroup>
                </Col>
                <Col md={6}>
                  <ListGroup variant="flush">
                    <ListGroup.Item>
                      <strong>Question Title:</strong> {question_details.title}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <strong>Question Description:</strong>{" "}
                      {question_details.description}
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <strong>Completed:</strong> {closed ? "Yes" : "No"}
                    </ListGroup.Item>
                    {completed && (
                      <ListGroup.Item>
                        <strong>Notes:</strong> {notes ?? "N/A"}
                      </ListGroup.Item>
                    )}
                    {vc_link && (
                      <ListGroup.Item>
                        <strong>Video Call:</strong>{" "}
                        <a
                          href={vc_link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Join Call
                        </a>
                      </ListGroup.Item>
                    )}
                  </ListGroup>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col className="text-center">
                  {!closed && (
                    <Button
                      className="mt-3"
                      variant="primary"
                      onClick={() =>
                        navigate(
                          `/sessions/${question_details.connection}/${id}`,
                        )
                      }
                    >
                      Start Interview Session
                    </Button>
                  )}
                  {closed && !completed && (
                    <Button
                      className="mt-3"
                      variant="primary"
                      onClick={() => navigate(`/sessions/grade/${id}`)}
                    >
                      Grade Session
                    </Button>
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
