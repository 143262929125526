import { Button, Spinner } from "react-bootstrap";
import { FaRobot } from "react-icons/fa";
import "./AICompleteButton.css";

export const AICompleteButton = ({ handleAIGenerate, AIGenerating }) => {
  return (
    <Button
      variant="success"
      type="button"
      onClick={handleAIGenerate}
      disabled={AIGenerating}
      className="ai-button"
    >
      {AIGenerating ? (
        <>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />{" "}
          Generating...
        </>
      ) : (
        <>
          <FaRobot style={{ marginRight: "8px" }} /> AI AutoComplete
        </>
      )}
    </Button>
  );
};
