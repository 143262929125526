import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import axiosInstance from "../axiosConfig";
import { useCommonContainerLogic } from "../containers/useCommonContainerLogic";
import { Button } from "react-bootstrap";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

export const CheckoutButton = ({ productId, callToAction }) => {
  useCommonContainerLogic();
  const handleClick = async () => {
    const stripe = await stripePromise;

    const { data } = await axiosInstance.post(
      `${apiBaseUrl}/payments/api/v1/create-checkout-session/${productId}`,
      {
        productId: productId,
      },
    );

    const { error } = await stripe.redirectToCheckout({
      sessionId: data.id,
    });

    if (error) {
      console.error("Error redirecting to checkout:", error);
    }
  };

  return <Button onClick={handleClick}>{callToAction}</Button>;
};
