import { useEffect } from "react";
import axios from "axios";

export const LogoutContainer = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  useEffect(() => {
    (async () => {
      try {
        const logoutUrl = `${apiBaseUrl}/accounts/api/v1/logout/`;
        await axios.post(
          logoutUrl,
          {
            refresh_token: localStorage.getItem("refresh_token"),
          },
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          },
        );
        localStorage.clear();
        axios.defaults.headers.common["Authorization"] = null;
        window.location.href = "/login";
      } catch (e) {
        console.error("logout not working", e);
      }
    })();
  }, [apiBaseUrl]);

  return <div></div>;
};
