import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { StartCreateQuestionView } from "../views/StartCreateQuestionView";
import axiosInstance from "../axiosConfig";
import { useCommonContainerLogic } from "./useCommonContainerLogic";

export const StartCreateQuestionContainer = () => {
  const [information, setInformation] = useState({
    title: "",
    description: "",
    type: "",
  });
  const [questionTypes, setQuestionTypes] = useState([]);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  useCommonContainerLogic();

  useEffect(() => {
    const fetchQuestionTypes = async () => {
      try {
        const response = await axiosInstance.get(
          `${apiBaseUrl}/interviewers/api/v1/question-types`,
        );
        setQuestionTypes(response.data);
      } catch (error) {
        console.error("Error fetching question types:", error);
      }
    };

    fetchQuestionTypes();
  }, [apiBaseUrl]);

  const handleChange = (e) => {
    setInformation({ ...information, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const selectedQuestionType = questionTypes.find(
      (type) => type.id.toString() === information.type,
    );

    let connection = "";

    if (selectedQuestionType) {
      connection = selectedQuestionType.connection;
    }

    try {
      const questionUrl = `${apiBaseUrl}/interviewers/api/v1/questions/create`;
      const response = await axiosInstance.post(questionUrl, {
        ...information,
      });
      const questionId = response.data.id;
      switch (connection) {
        case "ssh":
          navigate(`/questions/create/next/ssh/${questionId}`);
          break;
        case "rdp":
          navigate(`/questions/create/next/rdp/${questionId}`);
          break;
        default:
          console.error("Unknown connection: ", connection);
      }
    } catch (error) {
      console.error(
        "Error creating question:",
        error.response ? error.response.data : error,
      );
      if (error.response && error.response.status === 403) {
        setError("You do not have permission to perform this action.");
      } else if (error.response && error.response.status === 400) {
        setError("Incorrect data provided.");
      } else if (error.response && error.response.status === 429) {
        setError("You can only create 10 Custom Questions per day.");
      } else {
        setError("An unexpected error occurred.");
      }
    }
  };

  return (
    <StartCreateQuestionView
      information={information}
      questionTypes={questionTypes}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      error={error}
    />
  );
};
