import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Helmet } from "react-helmet";

export const DemoView = () => {
  return (
    <>
      <Helmet>
        <title>Demo</title>
        <meta
          name="description"
          content="Watch a demo of SessionScreen to see how it works."
        />
      </Helmet>
      <Container
        className="my-5 py-4"
        style={{ backgroundColor: "#f8f9fa", borderRadius: "8px" }}
      >
        <Row className="text-center mb-4">
          <Col>
            <h2 className="display-4 font-weight-bold">
              Watch a Demo of SessionScreen
            </h2>
            <p className="lead mt-3">
              Discover how SessionScreen can enhance your interview process.
              Watch our demo video below to learn more about its features and
              benefits.
            </p>
          </Col>
        </Row>
        <Row className="justify-content-center mb-4">
          <Col md={8}>
            <div
              style={{
                position: "relative",
                paddingBottom: "56.25%",
                height: 0,
                overflow: "hidden",
              }}
            >
              <iframe
                src="https://www.loom.com/embed/af145142b6f94489b8a5955908f58745?sid=f88becda-8dec-4020-a5eb-09b4310d34ab"
                frameBorder="0"
                title="SessionScreen demo"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
              ></iframe>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="text-center">
            <Button variant="primary" size="lg" href="/signup">
              Get Started for Free
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};
