import React, { useState, useEffect } from "react";
import { DataView } from "../views/DataView";
import { useCommonContainerLogic } from "./useCommonContainerLogic";
import { Button } from "react-bootstrap";
import { useContextMenu } from "../hooks/useContextMenu";
import { Link } from "react-router-dom";
import axiosInstance from "../axiosConfig";
import { useNavigate } from "react-router-dom";

export const JobOpeningsContainer = () => {
  const {
    searchTerm,
    setSearchTerm,
    orgId,
    orgName,
    isLoading,
    authLoading,
    emailVerified,
  } = useCommonContainerLogic();

  const [jobOpenings, setJobOpenings] = useState([]);
  const [initialJobOpenings, setInitialJobOpenings] = useState([]);
  const [lengthOfInitialData, setLengthOfInitialData] = useState(0);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const navigate = useNavigate();

  useEffect(() => {
    const fetchJobOpenings = async () => {
      if (orgId !== -1) {
        try {
          const response = await axiosInstance.get(
            `${apiBaseUrl}/jobs/api/v1/job/${orgId}`,
          );
          const transformedData = transformData(response.data);
          setJobOpenings(transformedData);
          setInitialJobOpenings(transformedData);
          setLengthOfInitialData(transformedData.length);
        } catch (error) {
          console.error("Error fetching job openings:", error);
        }
      }
    };

    const transformData = (data) => {
      return data.map((item) => ({
        title: item.title,
        id: item.id,
        location: item.location,
        date: item.created_at ? new Date(item.created_at).toLocaleString() : "",
        is_active: item.is_active,
      }));
    };

    fetchJobOpenings();
  }, [orgId, apiBaseUrl]);

  const { contextMenu, contextMenuRef, onContextMenu } = useContextMenu();

  const jobOpeningsColumns = [
    { header: "Title", field: "title" },
    { header: "Location", field: "location" },
    { header: "Is Active?", field: "is_active" },
    { header: "Date Created", field: "date" },
  ];

  const getContextMenuItems = (rowData) => {
    const items = [];

    items.push({
      label: "View Opening",
      onClick: () => {
        navigate(`/openings/${orgId}/${rowData.id}`);
      },
    });

    return items;
  };

  const CreateJobOpeningButton = () => (
    <Button
      as={Link}
      to="/openings/create"
      variant="primary"
      size="md"
      className="shadow-sm"
    >
      Create New Job Opening
    </Button>
  );

  const searchAction = () => {
    if (searchTerm === "") {
      setJobOpenings(initialJobOpenings);
    } else {
      const updatedJobOpenings = initialJobOpenings.filter((job) =>
        job.title.toLowerCase().includes(searchTerm.toLowerCase()),
      );
      setJobOpenings(updatedJobOpenings);
    }
  };

  return (
    <DataView
      orgName={orgName}
      data={jobOpenings}
      columns={jobOpeningsColumns}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      dataType="Job Openings"
      CreateButtonComponent={CreateJobOpeningButton}
      isLoading={isLoading}
      authLoading={authLoading}
      emailVerified={emailVerified}
      onContextMenu={onContextMenu}
      contextMenu={contextMenu}
      contextMenuRef={contextMenuRef}
      contextMenuItems={
        contextMenu.rowData ? getContextMenuItems(contextMenu.rowData) : []
      }
      searchAction={searchAction}
      lengthOfInitialData={lengthOfInitialData}
    />
  );
};
