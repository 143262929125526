import { useCallback, useRef, useState, useEffect } from "react";

export const useContextMenu = () => {
  const [contextMenu, setContextMenu] = useState({
    show: false,
    x: 0,
    y: 0,
    rowData: null,
    rowId: null,
  });
  const contextMenuRef = useRef(null);

  const onContextMenu = (e, rowData, rowId) => {
    e.preventDefault();
    setContextMenu({
      show: !contextMenu.show,
      x: e.pageX,
      y: e.pageY,
      rowData: rowData,
      rowId: rowId,
    });
  };

  const handleCloseContextMenu = useCallback(() => {
    setContextMenu((prevState) => ({ ...prevState, show: false }));
  }, []);

  const handleClickOutside = useCallback(
    (event) => {
      if (
        contextMenuRef.current &&
        !contextMenuRef.current.contains(event.target)
      ) {
        handleCloseContextMenu();
      }
    },
    [handleCloseContextMenu],
  );

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [handleClickOutside]);

  return {
    contextMenu,
    contextMenuRef,
    onContextMenu,
    handleCloseContextMenu,
  };
};
