import { useEffect, useState } from "react";
import { poll } from "./utils/polling";

export const useStartProcess = (
  apiBaseHostname,
  id,
  startProcess,
  checkProcessReady,
  token,
) => {
  const [isClosed, setClosed] = useState(false);
  const [isStarted, setStarted] = useState(true);
  const [isReady, setReady] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    startProcess(apiBaseHostname, id, token)
      .then(() => {
        return poll(
          () => checkProcessReady(apiBaseHostname, id, token),
          (response) => response.data.ready === true,
          2000,
        );
      })
      .then(() => {
        setReady(true);
      })
      .catch((error) => {
        if (error.response) {
          if (
            error.response.status === 410 &&
            error.response.data.text === "CLOSED"
          ) {
            setClosed(true);
          } else if (
            error.response.status === 403 &&
            error.response.data.text === "NOT_STARTED"
          ) {
            setStarted(false);
          } else {
            console.error("Error", error);
            setError(error);
          }
        }
      });
  }, [apiBaseHostname, id, startProcess, checkProcessReady, token]);

  return { isClosed, isStarted, isReady, error };
};
