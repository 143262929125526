import React, { useState } from "react";
import axios from "axios";
import { PasswordResetRequestView } from "../views/PasswordResetRequestView";

export const PasswordResetRequestContainer = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const handleChange = (e) => {
    setEmail(e.target.value);
    setError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const resetUrl = `${apiBaseUrl}/accounts/api/v1/password-reset/`;
      await axios.post(
        resetUrl,
        { email },
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
      setSuccess(true);
      setEmail("");
    } catch (error) {
      let errorMessage = "Error: Please try again later.";
      if (error.response && error.response.data) {
        errorMessage = "Error: " + Object.values(error.response.data).join(" ");
      }
      setError(errorMessage);
    }
  };

  return (
    <PasswordResetRequestView
      email={email}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      error={error}
      success={success}
    />
  );
};
