import { useStartProcess } from "./useStartProcess";
import { startSession, checkSessionReady } from "./utils/sessionService";

export const useSessionStart = (apiBaseHostname, sessionId, token) => {
  const usp = useStartProcess(
    apiBaseHostname,
    sessionId,
    startSession,
    checkSessionReady,
    token,
  );
  return usp;
};
