import React from "react";
import { InputGroup, FormControl, Button } from "react-bootstrap";

export const SearchBar = ({
  searchTerm,
  setSearchTerm,
  placeholder,
  searchAction,
}) => {
  return (
    <InputGroup size="md" className="shadow-sm">
      <FormControl
        placeholder={placeholder}
        aria-label={placeholder}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <Button
        variant="outline-secondary"
        id="button-addon2"
        onClick={searchAction}
      >
        Search
      </Button>
    </InputGroup>
  );
};
