import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

export const Footer = () => {
  return (
    <>
      <Container fluid className="text-center bg-dark text-white p-3">
        <Row>
          <Col md={4}>
            <p>&copy; 2024 SessionScreen. All rights reserved.</p>
          </Col>
          <Col md={4}>
            <LinkContainer to="/privacy-policy">
              <a href="#!" className="text-white">
                Privacy Policy
              </a>
            </LinkContainer>
          </Col>
          <Col md={4}>
            <LinkContainer to="/terms-of-service">
              <a href="#!" className="text-white">
                Terms of Service
              </a>
            </LinkContainer>
          </Col>
        </Row>
      </Container>
    </>
  );
};
