import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { JobPostingView } from "../views/JobPostingView";
import axiosInstance from "../axiosConfig";

export const JobPostingContainer = () => {
  const { jobId } = useParams();
  const [jobPosting, setJobPosting] = useState(null);
  const [candidate, setCandidate] = useState({
    first_name: "",
    last_name: "",
    linkedin_url: "",
    phone_number: "",
    email: "",
    resume: null,
  });
  const [boolAnswers, setBoolAnswers] = useState([]);
  const [textAnswers, setTextAnswers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const [applicationSubmitted, setApplicationSubmitted] = useState(false);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "resume") {
      setCandidate({ ...candidate, resume: files[0] });
    } else {
      setCandidate({ ...candidate, [name]: value });
    }
  };

  const handleTextAnswerChange = (questionId, answer) => {
    setTextAnswers((prevAnswers) => {
      const existingAnswer = prevAnswers.find((a) => a.question === questionId);
      if (existingAnswer) {
        return prevAnswers.map((a) =>
          a.question === questionId ? { ...a, answer } : a,
        );
      } else {
        return [...prevAnswers, { question: questionId, answer }];
      }
    });
  };

  const handleBoolAnswerChange = (questionId, answer) => {
    setBoolAnswers((prevAnswers) => {
      const existingAnswer = prevAnswers.find((a) => a.question === questionId);
      if (existingAnswer) {
        return prevAnswers.map((a) =>
          a.question === questionId ? { ...a, answer } : a,
        );
      } else {
        return [...prevAnswers, { question: questionId, answer }];
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const applicationData = {
      candidate: {
        first_name: candidate.first_name,
        last_name: candidate.last_name,
        linkedin_url: candidate.linkedin_url,
        phone_number: candidate.phone_number,
        email: candidate.email,
      },
      text_answers: textAnswers,
      boolean_answers: boolAnswers,
      job: jobId,
    };

    const formData = new FormData();
    formData.append("resume", candidate.resume);
    formData.append("application_data", JSON.stringify(applicationData));

    try {
      await axiosInstance.post(
        `${apiBaseUrl}/jobs/api/v1/job-application/${jobId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      setApplicationSubmitted(true);
      setCandidate({
        first_name: "",
        last_name: "",
        linkedin_url: "",
        phone_number: "",
        email: "",
      });
      setTextAnswers([]);
      setBoolAnswers([]);
      setSubmitError("");
    } catch (error) {
      console.error("Error submitting application:", error);
      if (error.response.code === 500) {
        setSubmitError("Error submitting application. Please try again later");
      } else {
        setSubmitError(error.response.statusText);
      }
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    const fetchJobPosting = async () => {
      try {
        const response = await axiosInstance.get(
          `${apiBaseUrl}/jobs/api/v1/job/view/${jobId}`,
        );
        setJobPosting(response.data);
      } catch (error) {
        console.error("Error fetching job posting:", error);
      }
      setIsLoading(false);
    };

    fetchJobPosting();
  }, [jobId, apiBaseUrl]);

  return (
    <JobPostingView
      jobPosting={jobPosting}
      isLoading={isLoading}
      textAnswers={textAnswers}
      handleTextAnswerChange={handleTextAnswerChange}
      boolAnswers={boolAnswers}
      handleBoolAnswerChange={handleBoolAnswerChange}
      handleSubmit={handleSubmit}
      handleInputChange={handleInputChange}
      candidate={candidate}
      applicationSubmitted={applicationSubmitted}
      isSubmitting={isSubmitting}
      submitError={submitError}
    />
  );
};
