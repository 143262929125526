import React, { createContext, useContext, useState, useEffect } from "react";
import axiosInstance from "./axiosConfig";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  const [isAuth, setIsAuth] = useState(false);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    const token = localStorage.getItem("access_token");
    if (token) {
      setIsAuth(true);
      refreshAccessToken().finally(() => setIsLoading(false));
    } else {
      setIsAuth(false);
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  const login = (token, refreshToken) => {
    localStorage.setItem("access_token", token);
    localStorage.setItem("refresh_token", refreshToken);
    setIsAuth(true);
    scheduleTokenRefresh();
  };

  const logout = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    setIsAuth(false);
  };

  const refreshAccessToken = async () => {
    try {
      const refreshToken = localStorage.getItem("refresh_token");

      const refreshUrl = `${apiBaseUrl}/token/refresh/`;
      const response = await axiosInstance.post(
        refreshUrl,
        {
          refresh: refreshToken,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      const { access } = response.data;
      localStorage.setItem("access_token", access);
      setIsAuth(true);
      scheduleTokenRefresh();
    } catch (error) {
      console.error("Error refreshing access token:", error);
      logout();
    }
  };

  const scheduleTokenRefresh = () => {
    const refreshSchedule = 60 * 5 * 1000;
    setTimeout(refreshAccessToken, refreshSchedule);
  };

  return (
    <AuthContext.Provider value={{ isAuth, isLoading, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
