import React, { useRef } from "react";
import {
  Alert,
  Button,
  Card,
  Container,
  Row,
  Col,
  Form,
  Spinner,
} from "react-bootstrap";
import { LoadingNotice } from "../component/LoadingNotice";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaArrowDown } from "react-icons/fa";
import "./JobPostingView.css";
import { useNavigate } from "react-router-dom";

export const JobPostingView = ({
  isLoading,
  jobPosting,
  textAnswers,
  handleTextAnswerChange,
  boolAnswers,
  handleBoolAnswerChange,
  handleInputChange,
  candidate,
  handleSubmit,
  applicationSubmitted,
  isSubmitting,
  submitError,
}) => {
  const applySectionRef = useRef(null);
  const navigate = useNavigate();

  const scrollToApplySection = () => {
    applySectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  if (isLoading) {
    return <LoadingNotice />;
  }

  if (jobPosting === null) {
    navigate("/");
  }

  const renderTextQuestions = (question) => {
    const answer =
      textAnswers.find((a) => a.question === question.id)?.answer || "";
    return (
      <Form.Group
        controlId={`text-question-${question.id}`}
        key={`text-${question.id}`}
        className="mt-3"
      >
        <Form.Label>
          <b>{question.name}</b>
          {question.required && <span className="text-danger">*</span>}
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="Write your answer here"
          value={answer}
          onChange={(e) => handleTextAnswerChange(question.id, e.target.value)}
          required={question.required}
        />
      </Form.Group>
    );
  };

  const renderBooleanQuestions = (question) => {
    const answer =
      boolAnswers.find((a) => a.question === question.id)?.answer || "";
    return (
      <Form.Group
        controlId={`bool-question-${question.id}`}
        key={`bool-${question.id}`}
        className="mt-3"
      >
        <Form.Label>
          <b>{question.name}</b>
          {question.required && <span className="text-danger"> *</span>}
        </Form.Label>
        <Form.Control
          as="select"
          value={answer}
          onChange={(e) => handleBoolAnswerChange(question.id, e.target.value)}
          required={question.required}
        >
          <option value="">Select an option</option>
          <option value="yes">Yes</option>
          <option value="no">No</option>
        </Form.Control>
      </Form.Group>
    );
  };

  return (
    <Container className="mt-4">
      {!jobPosting.is_active && (
        <Alert variant="info" className="text-center px-2 py-4" dismissible>
          This Job Posting Is Not Active
        </Alert>
      )}
      {submitError && (
        <Alert variant="danger" className="text-center px-2 py-4" dismissible>
          {submitError}
        </Alert>
      )}
      <Row className="justify-content-center">
        <Col md={8}>
          <Card className="p-4 shadow-sm">
            <Card.Body>
              <div className="d-flex justify-content-center align-items-center">
                <Card.Title className="mb-2">
                  <h1 className="display-4 text-center">{jobPosting.title}</h1>
                </Card.Title>
              </div>
              <Card.Subtitle className="mb-4 text-center">
                At: {jobPosting.organization_name}
              </Card.Subtitle>
              <Card.Subtitle className="mb-2 text-muted text-center">
                <FaMapMarkerAlt className="me-2" />
                {jobPosting.location}
              </Card.Subtitle>
              <div className="row justify-content-center">
                <div className="col-4">
                  <Button
                    variant="outline-primary"
                    className="w-100"
                    onClick={scrollToApplySection}
                  >
                    Apply <FaArrowDown />
                  </Button>
                </div>
              </div>
              <Card.Text className="mt-4">
                <div
                  dangerouslySetInnerHTML={{ __html: jobPosting.description }}
                />
              </Card.Text>
              <hr />
              <div ref={applySectionRef}>
                <Form onSubmit={handleSubmit}>
                  {applicationSubmitted && (
                    <Alert
                      variant="success"
                      className="text-center px-2 py-4"
                      dismissible
                    >
                      Application Submitted Successfully
                    </Alert>
                  )}
                  <h2 className="mb-4">Apply</h2>
                  <Form.Group controlId="candidateFirstName" className="mb-3">
                    <Form.Label>
                      First Name <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="first_name"
                      placeholder="Enter your first name"
                      value={candidate.first_name}
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="candidateLastName" className="mb-3">
                    <Form.Label>
                      Last Name <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="last_name"
                      placeholder="Enter your last name"
                      value={candidate.last_name}
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="candidateLinkedInUrl" className="mb-3">
                    <Form.Label>LinkedIn URL</Form.Label>
                    <Form.Control
                      type="url"
                      name="linkedin_url"
                      placeholder="https://www.linkedin.com/in/end-of-url"
                      value={candidate.resume_url}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="candidateResumeFile" className="mb-3">
                    <Form.Label>
                      Upload .pdf Resume <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="file"
                      name="resume"
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="candidatePhoneNumber" className="mb-3">
                    <Form.Label>
                      Phone Number <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="tel"
                      name="phone_number"
                      value={candidate.phone_number}
                      onChange={handleInputChange}
                      placeholder="111-1111"
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="candidateEmail" className="mb-4">
                    <Form.Label>
                      Email <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={candidate.email}
                      onChange={handleInputChange}
                      placeholder="email@email.com"
                      required
                    />
                  </Form.Group>

                  {jobPosting.text_questions.map((question) =>
                    renderTextQuestions(question),
                  )}

                  {jobPosting.boolean_questions.map((question) =>
                    renderBooleanQuestions(question),
                  )}

                  <br />
                  <div className="d-grid">
                    <Button
                      variant="primary"
                      type="submit"
                      size="lg"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />{" "}
                          Submitting...
                        </>
                      ) : (
                        <>Apply Now</>
                      )}
                    </Button>
                  </div>
                </Form>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <br />
    </Container>
  );
};
