import React from "react";
import {
  Button,
  Form,
  Container,
  Row,
  Col,
  Alert,
  Card,
} from "react-bootstrap";

export const PasswordResetRequestView = ({
  email,
  handleChange,
  handleSubmit,
  error,
  success,
}) => {
  return (
    <Container className="mt-5">
      {success && (
        <Alert variant="success" className="text-center px-2 py-4" dismissible>
          Password reset email has been sent!
        </Alert>
      )}
      {error && (
        <Alert variant="danger" className="text-center px-2 py-4" dismissible>
          {error}
        </Alert>
      )}
      <Row className="justify-content-md-center">
        <Col xs={12} md={8} lg={6}>
          <Card className="shadow-lg p-3 mb-5 bg-white rounded">
            <Card.Body>
              <h2 className="mb-4 text-center">Reset Password</h2>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    name="email"
                    value={email}
                    onChange={handleChange}
                    required
                    minLength="1"
                  />
                </Form.Group>

                <Button variant="primary" type="submit" className="w-100">
                  Send Reset Link
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
