import React, { useEffect, useState } from "react";
import axios from "axios";
import { PricingView } from "../views/PricingView";

export const PricingContainer = ({
  header,
  subtitle,
  currentProduct,
  callToAction,
  includeBadHire,
  includeTerminology,
  showCallToActionButton,
  showStripeCheckoutButton,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          `${apiBaseUrl}/payments/api/v1/products`,
        );
        console.log(response.data);
        setProducts(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, [apiBaseUrl]);

  return (
    <PricingView
      products={products}
      header={header}
      subtitle={subtitle}
      currentProduct={currentProduct}
      callToAction={callToAction}
      isLoading={isLoading}
      includeBadHire={includeBadHire}
      includeTerminology={includeTerminology}
      showCallToActionButton={showCallToActionButton}
      showStripeCheckoutButton={showStripeCheckoutButton}
    />
  );
};
