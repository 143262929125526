import React from "react";
import { Container, Row, Col, Card, Spinner } from "react-bootstrap";
import { SubmitCreateQuestionButton } from "../component/SubmitCreateQuestionButton";
import { CancelCreateQuestionButton } from "../component/CancelCreateQuestionButton";
import { HelpSidebar } from "../component/HelpSidebar"; // Adjust the import path as necessary

export const SubmitCreateQuestionView = ({
  isQuestionReady,
  questionTitle,
  questionDescription,
  terminalRef,
  questionId,
  type,
  videoRef,
  onMouseOver,
  onMouseLeave,
  cursorInTerminal,
}) => {
  return (
    <Container fluid="md" className="my-4">
      <Row className="mb-4">
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>{questionTitle}</Card.Title>
              <Card.Text>{questionDescription}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mb-4">
        <HelpSidebar
          includePreassessment={true}
          isInterview={false}
          questionId={questionId}
        />
      </Row>

      <Row className="mb-3">
        <Col>
          {!isQuestionReady ? (
            <center>
              <Spinner animation="border" role="status"></Spinner>
            </center>
          ) : type === "ssh" ? (
            <div
              id="terminal"
              data-testid="terminal"
              key={questionId}
              ref={terminalRef}
              style={{
                width: "100%",
                height: "768px",
                overflowY: "auto",
                border: "1px solid #ddd",
                borderRadius: "4px",
                padding: "10px",
                backgroundColor: "#000",
                color: "#fff",
              }}
            />
          ) : (
            <center>
              <canvas
                ref={videoRef}
                style={{
                  width: "100%",
                  height: "768px",
                  overflowY: "auto",
                  border: cursorInTerminal ? "1px solid blue" : "none",
                }}
                onMouseOver={onMouseOver}
                onMouseLeave={onMouseLeave}
              />
            </center>
          )}
        </Col>
      </Row>

      <Row
        className="d-flex justify-content-center align-items-center"
        style={{ height: "60px" }}
      >
        <SubmitCreateQuestionButton
          isQuestionReady={isQuestionReady}
          questionId={questionId}
        />
      </Row>
      <Row
        className="d-flex justify-content-center align-items-center"
        style={{ height: "60px" }}
      >
        <CancelCreateQuestionButton
          isQuestionReady={isQuestionReady}
          questionId={questionId}
        />
      </Row>
    </Container>
  );
};
