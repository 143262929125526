import React, { useEffect, useState } from "react";
import axiosInstance from "../axiosConfig";
import { useCommonContainerLogic } from "./useCommonContainerLogic";
import OrganizationView from "../views/OrganizationView";

export const OrganizationContainer = () => {
  const { orgName, planName, isLoading, authLoading, emailVerified } =
    useCommonContainerLogic();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [members, setMembers] = useState([]);
  const [referralCode, setReferralCode] = useState("");
  const [email, setEmail] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [inviteSent, setInviteSent] = useState(false);
  const [apiKey, setApiKey] = useState("");
  const [showApiKey, setShowApiKey] = useState(false);
  const [isReferralCopied, setIsReferralCopied] = useState(false);
  const [isApiKeyCopied, setIsApiKeyCopied] = useState(false);

  const copyToClipboard = (text, type) => {
    navigator.clipboard.writeText(text).then(() => {
      if (type === "referral") {
        setIsReferralCopied(true);
        setTimeout(() => setIsReferralCopied(false), 2000);
      } else if (type === "apiKey") {
        setIsApiKeyCopied(true);
        setTimeout(() => setIsApiKeyCopied(false), 2000);
      }
    });
  };

  useEffect(() => {
    const fetchOrganization = async () => {
      try {
        const response = await axiosInstance.get(
          `${apiBaseUrl}/accounts/api/v1/organization/details`,
        );
        const organization = response.data;
        console.log("organization: ", organization);
        setMembers(organization.email_addresses);
        setReferralCode(organization.referral_code);
        setTransactions(organization.transactions);
      } catch (error) {
        console.error("Error fetching organization information:", error);
      }
    };

    fetchOrganization();
  }, [apiBaseUrl]);

  const handleInvite = async (e) => {
    e.preventDefault();
    try {
      const data = { email_address: email };
      await axiosInstance.post(
        `${apiBaseUrl}/accounts/api/v1/organization/invite`,
        data,
      );
      setInviteSent(true);
    } catch (error) {
      console.error("Error sending invite:", error);
    }
    setEmail("");
  };

  const handleGenerateApiKey = async () => {
    try {
      const response = await axiosInstance.post(
        `${apiBaseUrl}/accounts/api/v1/apikeys/`,
      );
      console.log("response.data: ", response.data);
      const newApiKey = response.data.key;
      setApiKey(newApiKey);
      setShowApiKey(true);
    } catch (error) {
      console.error("Error generating API key:", error);
    }
  };

  const dismiss = (e) => {
    e.preventDefault();
    setInviteSent(false);
  };

  return (
    <OrganizationView
      organizationName={orgName}
      planName={planName}
      members={members}
      email={email}
      handleInvite={handleInvite}
      setEmail={setEmail}
      authLoading={authLoading}
      isLoading={isLoading}
      emailVerified={emailVerified}
      inviteSent={inviteSent}
      dismiss={dismiss}
      handleGenerateApiKey={handleGenerateApiKey}
      apiKey={apiKey}
      showApiKey={showApiKey}
      referralCode={referralCode}
      copyToClipboard={copyToClipboard}
      isReferralCopied={isReferralCopied}
      isApiKeyCopied={isApiKeyCopied}
      transactions={transactions}
    />
  );
};
