import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../axiosConfig";
import { useBanner } from "../BannerContext";
import { Col, Button, Spinner } from "react-bootstrap";

export const SubmitCreateQuestionButton = ({ questionId, isQuestionReady }) => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const { showBanner } = useBanner();
  const [isSavingQuestion, setIsSavingQuestion] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsSavingQuestion(true);
      const questionUrl = `${apiBaseUrl}/interviewers/api/v1/questions/save/${questionId}`;
      await axiosInstance.put(questionUrl);
      showBanner(
        `Question is being processed. We will email you when complete.`,
        "success",
      );
      navigate(`/dashboard/questions`);
    } catch (error) {
      setIsSavingQuestion(false);
      showBanner("Failed to save question. Please try again.", "danger");
      console.error(
        "Error saving question:",
        error.response ? error.response.data : error,
      );
    }
  };

  return (
    <Col className="d-flex justify-content-center">
      {isSavingQuestion ? (
        <Button variant="primary" disabled>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          <span className="visually-hidden">Saving...</span>
        </Button>
      ) : (
        <Button
          variant="primary"
          type="submit"
          onClick={handleSubmit}
          disabled={!isQuestionReady}
        >
          {!isQuestionReady ? (
            <div>Starting Environment</div>
          ) : (
            <div>Save Question</div>
          )}
        </Button>
      )}
    </Col>
  );
};
