import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

export const PrivacyPolicyView = () => {
  return (
    <>
      <Container className="my-5">
        <Row className="align-items-center text-center">
          <Col md={12}>
            <h1>Privacy Policy</h1>
            <p>Last updated: June 27, 2024</p>
            <br />
            <p>
              PraxisGrid, Inc ("we", "our", "us") is committed to protecting and
              respecting your privacy. This Privacy Policy explains how we
              collect, use, and safeguard your information when you visit our
              website https://sessionscreen.com ("Site") and use our services.
              Please read this policy carefully to understand our views and
              practices regarding your personal data and how we will treat it.
            </p>
            <br />
          </Col>
        </Row>
      </Container>

      <Container className="my-5">
        <Row>
          <Col md={12}>
            <h2>1. Information We Collect</h2>
            <p>We may collect and process the following data about you:</p>
            <ul>
              <li>
                <strong>Personal Identification Information:</strong> Full name,
                email address, and any other information you voluntarily provide
                when creating an account or using our services.
              </li>
              <li>
                <strong>Candidate Information:</strong> First names, last names,
                resume urls, phone numbers, and email addresses of candidates
                added by users for the purpose of scheduling and conducting
                interviews.
              </li>
              <li>
                <strong>Technical Data:</strong> IP address, browser type and
                version, time zone setting, browser plug-in types and versions,
                operating system and platform, and other technology on the
                devices you use to access this Site.
              </li>
              <li>
                <strong>Usage Data:</strong> Information about how you use our
                Site, products, and services.
              </li>
              <li>
                <strong>Marketing and Communications Data:</strong> Your
                preferences in receiving marketing from us and your
                communication preferences.
              </li>
              <li>
                <strong>Sensitive Data:</strong> We do not intentionally collect
                sensitive personal data. If we do so, we will obtain your
                explicit consent.
              </li>
              <li>
                <strong>Children's Data:</strong> We do not knowingly collect
                personal data from children under 16 without parental consent.
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h2>2. How We Use Your Information</h2>
            <p>We use the information we collect in the following ways:</p>
            <ul>
              <li>
                <strong>To Provide and Manage Our Services:</strong> Creating
                and managing your account, providing you with access to our
                services, and managing your requests.
              </li>
              <li>
                <strong>To Communicate with You:</strong> Sending you updates,
                notifications, and other information related to your use of our
                services.
              </li>
              <li>
                <strong>To Improve Our Services:</strong> Analyzing how you use
                our Site and services to improve and develop new features.
              </li>
              <li>
                <strong>To Ensure Security:</strong> Protecting your information
                and our Site from unauthorized access, fraud, or other illegal
                activities.
              </li>
              <li>
                <strong>To Comply with Legal Obligations:</strong> Ensuring
                compliance with applicable laws and regulations.
              </li>
              <li>
                <strong>With Your Consent:</strong> We may process your data for
                specific purposes with your consent, which you can withdraw at
                any time.
              </li>
              <li>
                <strong>Automated Decision Making:</strong> We do not use your
                data for automated decision-making without your explicit
                consent.
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h2>3. How We Share Your Information</h2>
            <p>
              We do not sell, trade, or otherwise transfer your personal
              information to outside parties except in the following
              circumstances:
            </p>
            <ul>
              <li>
                <strong>Service Providers:</strong> We may share your
                information with third-party service providers who assist us in
                operating our Site, conducting our business, or servicing you,
                so long as those parties agree to keep this information
                confidential.
              </li>
              <li>
                <strong>Legal Requirements:</strong> We may disclose your
                information when required by law or to comply with a legal
                obligation.
              </li>
              <li>
                <strong>Business Transfers:</strong> In the event of a merger,
                acquisition, or sale of all or a portion of our assets, your
                personal data may be transferred to the acquiring company.
              </li>
              <li>
                <strong>International Transfers:</strong> Your information may
                be transferred to and maintained on servers located outside of
                your state, province, country, or other governmental
                jurisdiction where the data protection laws may differ from
                those of your jurisdiction. We will take all steps reasonably
                necessary to ensure that your data is treated securely and in
                accordance with this Privacy Policy.
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h2>4. Data Security</h2>
            <p>
              We implement a variety of security measures to maintain the safety
              of your personal information when you enter, submit, or access
              your personal information. This includes encryption of passwords
              and use of HTTPS to secure data transmission. We also have
              procedures in place to deal with any suspected data breach and
              will notify you and any applicable regulator of a breach where we
              are legally required to do so.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h2>5. Your Data Protection Rights</h2>
            <p>
              Depending on your location, you may have the following rights
              regarding your personal data:
            </p>
            <ul>
              <li>
                <strong>Access:</strong> You have the right to request copies of
                your personal data.
              </li>
              <li>
                <strong>Rectification:</strong> You have the right to request
                that we correct any information you believe is inaccurate or
                complete information you believe is incomplete.
              </li>
              <li>
                <strong>Erasure:</strong> You have the right to request that we
                erase your personal data, under certain conditions.
              </li>
              <li>
                <strong>Restrict Processing:</strong> You have the right to
                request that we restrict the processing of your personal data,
                under certain conditions.
              </li>
              <li>
                <strong>Data Portability:</strong> You have the right to request
                that we transfer the data that we have collected to another
                organization, or directly to you, under certain conditions.
              </li>
              <li>
                <strong>Object to Processing:</strong> You have the right to
                object to our processing of your personal data, under certain
                conditions.
              </li>
              <li>
                <strong>Withdraw Consent:</strong> If you have given your
                consent to the processing of your personal data, you have the
                right to withdraw your consent at any time.
              </li>
            </ul>
            <p>
              To exercise any of these rights, please contact us at
              privacy@sessionscreen.com. We aim to respond to all legitimate
              requests within one month.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h2>6. Cookies and Tracking Technologies</h2>
            <p>
              We use cookies and similar tracking technologies to track the
              activity on our Site and hold certain information. Cookies are
              files with a small amount of data which may include an anonymous
              unique identifier. You can instruct your browser to refuse all
              cookies or to indicate when a cookie is being sent. However, if
              you do not accept cookies, you may not be able to use some
              portions of our Site.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h2>7. Retention of Data</h2>
            <p>
              We will retain your personal data only for as long as is necessary
              for the purposes set out in this Privacy Policy. We will retain
              and use your personal data to the extent necessary to comply with
              our legal obligations (for example, if we are required to retain
              your data to comply with applicable laws), resolve disputes, and
              enforce our legal agreements and policies.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h2>8. Third-Party Links</h2>
            <p>
              Our Site may contain links to other websites that are not operated
              by us. If you click on a third-party link, you will be directed to
              that third party's site. We strongly advise you to review that
              third party's privacy policy. We have no control over and assume
              no responsibility for the content, privacy policies, or practices
              of any third-party sites or services.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <h2>9. Changes to This Privacy Policy</h2>
            <p>
              We may update our Privacy Policy from time to time. We will notify
              you of any changes by posting the new Privacy Policy on this page.
              You are advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they
              are posted on this page.
            </p>
          </Col>
        </Row>
      </Container>

      <Container fluid className="text-center bg-light p-5">
        <h2>Need more information?</h2>
        <LinkContainer to="/contact-us">
          <button className="btn btn-primary btn-lg">Contact Us</button>
        </LinkContainer>
      </Container>
      <br />
    </>
  );
};
