import React, { useRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "xterm/css/xterm.css";
import { useCommonContainerLogic } from "./useCommonContainerLogic";
import { useQuestionStart } from "../hooks/useQuestionStart";
import { useWebSocketConnection } from "../hooks/useWebSocketConnection";
import { SubmitCreateQuestionView } from "../views/SubmitCreateQuestionView";
import axiosInstance from "../axiosConfig";
import htmr from "htmr";

export const SubmitCreateQuestionContainer = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  let { questionId } = useParams();
  const terminalRef = useRef(null);
  const [questionTitle, setQuestionTitle] = useState("");
  const [questionDescription, setQuestionDescription] = useState("");
  const [sessionId, setSessionId] = useState(null);
  const [uuid, setUuid] = useState(null);
  const websocketPath = "question";

  useEffect(() => {
    const fetchQuestion = async () => {
      try {
        const url = `${apiBaseUrl}/interviewers/api/v1/questions/${questionId}`;
        const response = await axiosInstance.get(url);
        const responseData = response.data;
        setQuestionDescription(htmr(responseData.description));
        setQuestionTitle(htmr(responseData.title));
        setSessionId(responseData.id);
        setUuid(responseData.uuid);
      } catch (error) {
        console.error("Error fetching question:", error);
      }
    };

    fetchQuestion();
  }, [apiBaseUrl, questionId]);

  const isQuestionReady = useQuestionStart(apiBaseUrl, sessionId);

  useWebSocketConnection(
    isQuestionReady && sessionId,
    apiBaseUrl,
    sessionId,
    terminalRef,
    websocketPath,
    uuid,
  );

  useCommonContainerLogic();

  return (
    <SubmitCreateQuestionView
      isQuestionReady={isQuestionReady}
      questionTitle={questionTitle}
      questionDescription={questionDescription}
      terminalRef={terminalRef}
      questionId={questionId}
      type="ssh"
    />
  );
};
