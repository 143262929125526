import React, { useState, useEffect } from "react";
import { DataView } from "../views/DataView";
import { useCommonContainerLogic } from "./useCommonContainerLogic";
import { useContextMenu } from "../hooks/useContextMenu";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import axiosInstance from "../axiosConfig";
import { useNavigate } from "react-router-dom";
export const QuestionContainer = () => {
  const {
    searchTerm,
    setSearchTerm,
    orgName,
    isLoading,
    authLoading,
    emailVerified,
  } = useCommonContainerLogic();

  const [questions, setQuestions] = useState([]);
  const [initialQuestions, setInitialQuestions] = useState([]);
  const [lengthOfInitialData, setLengthOfInitialData] = useState(0);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await axiosInstance.get(
          `${apiBaseUrl}/interviewers/api/v1/questions`,
        );
        setQuestions(response.data);
        setInitialQuestions(response.data);
        setLengthOfInitialData(response.data.length);
      } catch (error) {
        console.error("Error fetching question types:", error);
      }
    };

    fetchQuestions();
  }, [apiBaseUrl]);

  const { contextMenu, contextMenuRef, onContextMenu, handleCloseContextMenu } =
    useContextMenu();

  const sessionColumns = [
    { header: "Title", field: "title" },
    { header: "Description", field: "description" },
  ];

  const contextMenuItems = [
    {
      label: "Update Question",
      onClick: (question) => {
        navigate(`/questions/update/${question.id}`);
      },
    },
    {
      label: "Delete Question",
      onClick: async (question) => {
        try {
          const response = await axiosInstance.delete(
            `${apiBaseUrl}/interviewers/api/v1/questions/delete/${question.id}`,
          );
          if (response.status === 204) {
            const newQuestions = [...questions];
            const updatedQuestions = newQuestions.filter(
              (q) => q.id !== question.id,
            );
            setQuestions(updatedQuestions);
            handleCloseContextMenu();
            window.location.reload();
          }
        } catch (error) {
          console.error("Error deleting questions:", error);
        }
      },
    },
  ];

  const CreateQuestionButton = () => {
    return (
      <Button
        as={Link}
        to="/questions/create"
        variant="primary"
        size="md"
        className="shadow-sm"
      >
        Create New Question
      </Button>
    );
  };

  const searchAction = () => {
    if (searchTerm === "") {
      setQuestions(initialQuestions);
    } else {
      const updatedQuestions = initialQuestions.filter((q) =>
        q.title.toLowerCase().includes(searchTerm.toLowerCase()),
      );
      setQuestions(updatedQuestions);
    }
  };

  return (
    <DataView
      orgName={orgName}
      data={questions}
      columns={sessionColumns}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      dataType="Questions"
      CreateButtonComponent={CreateQuestionButton}
      isLoading={isLoading}
      authLoading={authLoading}
      emailVerified={emailVerified}
      onContextMenu={onContextMenu}
      contextMenu={contextMenu}
      contextMenuRef={contextMenuRef}
      contextMenuItems={contextMenuItems}
      searchAction={searchAction}
      lengthOfInitialData={lengthOfInitialData}
    />
  );
};
