import React, { useState } from "react";
import { Offcanvas, Button, Accordion } from "react-bootstrap";

const TerminologySidebar = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="link" onClick={handleShow} className="p-0">
        What is an Interview?
      </Button>

      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>🖥️ SessionScreen</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>What is an Interview?</Accordion.Header>
              <Accordion.Body>
                An Interview is either a one-on-one interview with a candidate
                or a pre-assessment
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>What is a Custom Question?</Accordion.Header>
              <Accordion.Body>
                A Custom Question is a computer environment you configure for
                Interviews. For example, you might install Microsoft Excel with
                a broken formula. During an Interview, you and the candidate
                would work together to debug the broken formula.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>How It Works</Accordion.Header>
              <Accordion.Body>
                Create a Custom Question and assign it to candidates!
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default TerminologySidebar;
