import axiosInstance from "../axiosConfig";
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

export const cancelQuestion = async (questionId) => {
  try {
    const questionUrl = `${apiBaseUrl}/interviewers/api/v1/questions/cancel/${questionId}`;
    await axiosInstance.delete(questionUrl);
    window.location.reload();
  } catch (error) {
    console.error(
      "Error saving question:",
      error.response ? error.response.data : error,
    );
  }
};
