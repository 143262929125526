import React, { useState } from "react";
import { Offcanvas, Tabs, Tab, Button, Spinner } from "react-bootstrap";
import { FaCopy } from "react-icons/fa";
import axiosInstance from "../axiosConfig";

export const HelpSidebar = ({
  includePreassessment,
  isInterview,
  questionId,
}) => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [show, setShow] = useState(false);
  const [containsScript, setContainsScript] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const checkScript = async () => {
    setLoading(true);
    try {
      await axiosInstance.get(
        `${apiBaseUrl}/interviewers/api/v1/questions/contains_script/${questionId}`,
      );
      setContainsScript(true);
    } catch (error) {
      console.error("Error checking script:", error);
      setContainsScript(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button variant="link" onClick={handleShow}>
        Would you like assistance? Click here!
      </Button>

      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Help</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Tabs defaultActiveKey="tips" id="help-tabs" className="mb-3">
            <Tab eventKey="tips" title="Tips">
              <ul>
                <li>
                  Since we are booting up new computers, it might take a few
                  minutes for them to launch.
                </li>
                <li>
                  The simplest way to resolve most issues is to either reload
                  the page or open it in a new tab.
                </li>
                <li>
                  The computer below will be available for 1.5 hours after it
                  starts.
                </li>
                <li>
                  We disabled keyboard actions in the browser during Windows
                  sessions to improve controls.
                </li>
                <li>
                  If you are working on a Linux computer, you may need to click
                  on the terminal and hit 'Enter' on your keyboard if you see a
                  blank black screen.
                </li>
                {!isInterview && (
                  <li>
                    Ensure that all changes you make to the computer will
                    persist after a reboot.
                  </li>
                )}
                {!isInterview && (
                  <li>
                    You can work on the question below with any colleague logged
                    into SessionScreen. Simply send them this link:
                    <button
                      onClick={() => {
                        navigator.clipboard.writeText(window.location.href);
                        alert("Link copied to clipboard");
                      }}
                      style={{
                        marginLeft: "10px",
                        cursor: "pointer",
                        background: "none",
                        border: "none",
                      }}
                    >
                      <FaCopy />
                    </button>
                  </li>
                )}
              </ul>
            </Tab>
            {includePreassessment && (
              <Tab eventKey="faq" title="Pre-Assessment">
                <ul>
                  <li>
                    If you want to use this question as a pre-assessment, you
                    will need to create a <code>sessionscreen</code> script
                  </li>
                  <li>
                    Your candidate passes your pre-assessment if your{" "}
                    <code>sessionscreen</code> script has <code>exit 0</code>
                  </li>
                  <li>
                    On Linux, simply create an executable here:
                    <br />
                    <code>/usr/local/bin/sessionscreen.sh</code>
                  </li>
                  <li>
                    On Windows, create a powershell script here:
                    <br />
                    <code>
                      C:\Program
                      Files\WindowsPowerShell\Scripts\sessionscreen.ps1
                    </code>
                  </li>
                  <li>
                    <Button onClick={checkScript} disabled={loading}>
                      {loading ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />{" "}
                          Checking...
                        </>
                      ) : (
                        "Check if your Question contains a reachable sessionscreen script"
                      )}
                    </Button>
                  </li>
                  {containsScript !== null && (
                    <li>{containsScript ? "Reachable!" : "Not Reachable."}</li>
                  )}
                </ul>
              </Tab>
            )}
            <Tab eventKey="contact" title="Contact">
              You can always get support from the SessionScreen Discord channel{" "}
              <a href="https://support.sessionscreen.com">here</a>
            </Tab>
          </Tabs>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};
