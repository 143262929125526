import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { GradeSessionView } from "../views/GradeSessionView";
import axiosInstance from "../axiosConfig";
import { useBanner } from "../BannerContext";
import { useCommonContainerLogic } from "./useCommonContainerLogic";
import htmr from "htmr";

export const GradeSessionContainer = () => {
  const { isLoading, authLoading, emailVerified } = useCommonContainerLogic();
  let { sessionId } = useParams();
  const { showBanner } = useBanner();
  const [error, setError] = useState("");
  const [sessionTitle, setSessionTitle] = useState("");
  const [formData, setFormData] = useState({
    grade: "",
    notes: "",
  });

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setError("");
  };

  useEffect(() => {
    const fetchQuestion = async () => {
      try {
        const url = `${apiBaseUrl}/interviewers/api/v1/question-attempts/basic/${sessionId}`;
        const response = await axiosInstance.get(url);
        const responseData = response.data;
        setSessionTitle(htmr(responseData.title));
      } catch (error) {
        console.error("Error fetching question:", error);
      }
    };

    fetchQuestion();
  }, [apiBaseUrl, sessionId]);

  const canSubmit = () => {
    if (!formData.grade || !formData.notes) {
      setError("Please fill in all fields.");
      return false;
    }
    if (!isNumeric(formData.grade)) {
      setError("Grade must be a number.");
      return false;
    }
    if (parseFloat(formData.grade) < 0) {
      setError("Grade must be positive.");
      return false;
    }
    if (parseFloat(formData.grade) > 10) {
      setError("Grade must be a number between 1 and 10.");
      return false;
    }
    if (formData.notes.length > 500) {
      setError("Please limit your notes to 500 characters.");
      return false;
    }
    return true;
  };

  const isNumeric = (str) => {
    if (typeof str != "string") return false;
    return !isNaN(str) && !isNaN(parseFloat(str));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!canSubmit()) return;

    try {
      const sessionUrl = `${apiBaseUrl}/interviewers/api/v1/question-attempts/grade/${sessionId}`;
      const formDataClone = { ...formData };
      formDataClone.grade = parseFloat(formDataClone.grade);
      await axiosInstance.put(sessionUrl, formDataClone);
      showBanner("This session has been submitted successfully", "success");
      navigate("/dashboard/sessions");
    } catch (error) {
      if (
        error.response.status === 400 &&
        error.response.data.text === "COMPLETED"
      ) {
        let errorMessage =
          "This interview session is completed and cannot be graded";
        setError(errorMessage);
      } else if (
        error.response.status === 400 &&
        error.response.data.text === "NOT_CLOSED"
      ) {
        let errorMessage =
          "You cannot grade an interview session that's not closed";
        setError(errorMessage);
      } else {
        let errorMessage = "Error grading session. Please try again later.";
        if (error.response && error.response.data) {
          errorMessage =
            "Error grading session: " +
            Object.values(error.response.data).join(" ");
          setError(errorMessage);
        }
      }
    }
  };

  return (
    <GradeSessionView
      sessionTitle={sessionTitle}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      error={error}
      authLoading={authLoading}
      isLoading={isLoading}
      emailVerified={emailVerified}
    />
  );
};
